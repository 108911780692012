import { useState, useEffect } from 'react'
import { cn } from "../../lib/utils"
import PropTypes from 'prop-types'

const FILTER_STATE_KEY = 'tourFilterState'

function TourFilters({ onFilterChange, onReset, className }) {
  // Load initial state from localStorage
  const savedState = JSON.parse(localStorage.getItem(FILTER_STATE_KEY) || '{}')
  const [startDate, setStartDate] = useState(savedState.startDate || '')
  const [endDate, setEndDate] = useState(savedState.endDate || '')
  const [location, setLocation] = useState(savedState.location || '')
  const [searchText, setSearchText] = useState(savedState.searchText || '')
  const [searchField, setSearchField] = useState(savedState.searchField || 'all')

  // Save filter state to localStorage whenever it changes
  useEffect(() => {
    const filterState = {
      startDate,
      endDate,
      location,
      searchText,
      searchField
    }
    localStorage.setItem(FILTER_STATE_KEY, JSON.stringify(filterState))
  }, [startDate, endDate, location, searchText, searchField])

  const handleDateChange = (type, value) => {
    if (type === 'start') {
      setStartDate(value)
    } else {
      setEndDate(value)
    }
    onFilterChange({
      startDate: type === 'start' ? value : startDate,
      endDate: type === 'end' ? value : endDate,
      location,
      searchText,
      searchField
    })
  }

  const handleLocationChange = (e) => {
    setLocation(e.target.value)
    onFilterChange({
      startDate,
      endDate,
      location: e.target.value,
      searchText,
      searchField
    })
  }

  const handleSearchChange = (e) => {
    setSearchText(e.target.value)
    onFilterChange({
      startDate,
      endDate,
      location,
      searchText: e.target.value,
      searchField
    })
  }

  const handleSearchFieldChange = (e) => {
    setSearchField(e.target.value)
    onFilterChange({
      startDate,
      endDate,
      location,
      searchText,
      searchField: e.target.value
    })
  }

  const handleReset = () => {
    setStartDate('')
    setEndDate('')
    setLocation('')
    setSearchText('')
    setSearchField('all')
    localStorage.removeItem(FILTER_STATE_KEY)
    onReset()
  }

  return (
    <div className={cn("space-y-4", className)}>
      <div className="flex flex-wrap gap-4 mb-4">
        <div className="flex-1 min-w-[200px]">
          <label className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">
            Start Date
          </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => handleDateChange('start', e.target.value)}
            className="w-full px-3 py-2 bg-white dark:bg-slate-800 text-slate-900 dark:text-white rounded border border-slate-300 dark:border-slate-700 focus:outline-none focus:border-blue-500 transition-theme"
          />
        </div>
        <div className="flex-1 min-w-[200px]">
          <label className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">
            End Date
          </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => handleDateChange('end', e.target.value)}
            className="w-full px-3 py-2 bg-white dark:bg-slate-800 text-slate-900 dark:text-white rounded border border-slate-300 dark:border-slate-700 focus:outline-none focus:border-blue-500 transition-theme"
          />
        </div>
        <div className="flex-1 min-w-[200px]">
          <label className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">
            Location
          </label>
          <input
            type="text"
            value={location}
            onChange={handleLocationChange}
            placeholder="City or Country"
            className="w-full px-3 py-2 bg-white dark:bg-slate-800 text-slate-900 dark:text-white rounded border border-slate-600 dark:border-slate-700 focus:outline-none focus:border-blue-500 transition-theme"
          />
        </div>
      </div>
      
      {/* Search Section */}
      <div className="flex flex-wrap gap-4 mb-4">
        <div className="flex-1 min-w-[200px]">
          <label className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">
            Search
          </label>
          <input
            type="text"
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search performances..."
            className="w-full px-3 py-2 bg-white dark:bg-slate-800 text-slate-900 dark:text-white rounded border border-slate-300 dark:border-slate-700 focus:outline-none focus:border-blue-500 transition-theme"
          />
        </div>
        <div className="w-[200px]">
          <label className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">
            Search In
          </label>
          <select
            value={searchField}
            onChange={handleSearchFieldChange}
            className="w-full px-3 py-2 bg-white dark:bg-slate-800 text-slate-900 dark:text-white rounded border border-slate-300 dark:border-slate-700 focus:outline-none focus:border-blue-500 transition-theme"
          >
            <option value="all">All Fields</option>
            <option value="venue">Venue</option>
            <option value="city">City</option>
            <option value="country">Country</option>
            <option value="date">Date</option>
            <option value="time">Time</option>
            <option value="status">Status</option>
            <option value="eventType">Event Type</option>
          </select>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleReset}
          className="px-4 py-2 bg-slate-800 dark:bg-slate-700 hover:bg-slate-700 dark:hover:bg-slate-600 text-white rounded transition-theme"
        >
          Reset Filters
        </button>
      </div>
    </div>
  )
}

TourFilters.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default TourFilters
