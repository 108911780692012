import { analytics } from '../services/AnalyticsService'

const ORDER_PREFIX = 'JM'  // Jean's Music prefix
const SEQUENCE_LENGTH = 6

export const generateOrderReference = () => {
  const timestamp = Date.now().toString(36)
  const randomPart = Math.random().toString(36).substring(2, 5)
  const sequence = (Math.floor(Math.random() * Math.pow(10, SEQUENCE_LENGTH)))
    .toString()
    .padStart(SEQUENCE_LENGTH, '0')
    
  return `${ORDER_PREFIX}-${timestamp}-${sequence}-${randomPart}`.toUpperCase()
}

export const formatOrderDate = (date) => {
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'long',
    timeStyle: 'short'
  }).format(date)
}

export const sendOrderConfirmationEmail = async (orderDetails) => {
  try {
    const response = await fetch('/api/send-order-confirmation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(orderDetails)
    })

    if (!response.ok) {
      throw new Error('Failed to send order confirmation email')
    }

    analytics.trackEvent('order_confirmation_email_sent', {
      orderId: orderDetails.orderReference
    })

    return true
  } catch (error) {
    analytics.logError(error, { 
      context: 'orderConfirmationEmail',
      orderId: orderDetails.orderReference 
    })
    throw error
  }
}
