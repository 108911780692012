import PropTypes from 'prop-types'
import { cn } from '../../lib/utils'
import { useRef, useEffect, Children, cloneElement } from 'react'

const GalleryGrid = ({ children, className }) => {
  const gridRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.dataset.visible = 'true'
            observer.unobserve(entry.target)
          }
        })
      },
      {
        root: null,
        rootMargin: '50px',
        threshold: 0.1
      }
    )

    const gridItems = gridRef.current?.children
    if (gridItems) {
      Array.from(gridItems).forEach(item => {
        observer.observe(item)
      })
    }

    return () => observer.disconnect()
  }, [children])
  return (
    <div 
      ref={gridRef}
      className={cn(
        "grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4",
        "auto-rows-[minmax(200px,auto)]",
        "transition-[opacity,transform] duration-300 ease-in-out",
        className
      )}
    >
      {Children.map(children, child => 
        cloneElement(child, {
          className: cn(
            child.props.className,
            "opacity-0 transition-opacity duration-500 data-[visible=true]:opacity-100"
          )
        })
      )}
    </div>
  )
}

GalleryGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default GalleryGrid
