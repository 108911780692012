import { useReducer, useEffect, useCallback, useRef } from 'react'
import { analytics } from '../services/AnalyticsService'
import PropTypes from 'prop-types'
import { 
  generateSessionId, 
  generateCartItemId,
  validateQuantity,
  saveCartToStorage,
  loadCartFromStorage,
  clearCartFromStorage,
  mergeCartItems
} from '../utils/CartUtils'
import { CartContext } from './cart-context'

const initialState = {
  items: [],
  lastUpdated: Date.now(),
  sessionId: generateSessionId(),
  isLoading: true,
  error: null,
  lastAction: null,
  undoStack: [],
  shippingAddress: null,
  shippingOption: null,
  paymentStatus: null,
  paymentError: null
}

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'INITIALIZE_CART':
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    
    case 'ADD_ITEM': {
      const newItems = mergeCartItems(state.items, [action.payload])
      return {
        ...state,
        items: newItems,
        lastUpdated: Date.now()
      }
    }
    
    case 'REMOVE_ITEM':
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload),
        lastUpdated: Date.now()
      }
    
    case 'UPDATE_QUANTITY': {
      const { itemId, quantity } = action.payload
      return {
        ...state,
        items: state.items.map(item => 
          item.id === itemId
            ? { ...item, quantity: validateQuantity(quantity, item.maxQuantity) }
            : item
        ),
        lastUpdated: Date.now()
      }
    }
    
    case 'BULK_ADD': {
      const newItems = mergeCartItems(state.items, action.payload)
      return {
        ...state,
        items: newItems,
        lastUpdated: Date.now()
      }
    }
    
    case 'BULK_REMOVE':
      return {
        ...state,
        items: state.items.filter(item => !action.payload.includes(item.id)),
        lastUpdated: Date.now()
      }
    
    case 'CLEAR_CART':
      return {
        ...state,
        items: [],
        lastUpdated: Date.now()
      }
    
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    
    case 'SET_SHIPPING_ADDRESS':
      return {
        ...state,
        shippingAddress: action.payload,
        lastUpdated: Date.now()
      }
    
    case 'SET_SHIPPING_OPTION':
      return {
        ...state,
        shippingOption: action.payload,
        lastUpdated: Date.now()
      }
    
    case 'SET_PAYMENT_STATUS':
      return {
        ...state,
        paymentStatus: action.payload,
        lastUpdated: Date.now()
      }

    case 'SET_PAYMENT_ERROR':
      return {
        ...state,
        paymentError: action.payload,
        lastUpdated: Date.now()
      }
    
    default:
      return state
  }
}

export function CartProvider({ children }) {
  const [state, dispatch] = useReducer(cartReducer, initialState)

  // Initialize analytics
  useEffect(() => {
    analytics.init();
  }, []);

  // Track performance metrics
  const performanceRef = useRef({
    lastOperation: null,
    startTime: null
  });

  const trackOperation = (operation) => {
    if (performanceRef.current.lastOperation) {
      const duration = performance.now() - performanceRef.current.startTime;
      analytics.trackMetric(
        `cart_${performanceRef.current.lastOperation}_duration`,
        duration
      );
    }
    performanceRef.current = {
      lastOperation: operation,
      startTime: performance.now()
    };
  };

  // Initialize cart from storage and setup sync
  useEffect(() => {
    try {
      trackOperation('init');
      const savedCart = loadCartFromStorage();
      
      if (savedCart) {
        dispatch({ 
          type: 'INITIALIZE_CART', 
          payload: {
            items: savedCart.items,
            lastUpdated: savedCart.lastUpdated,
            sessionId: savedCart.sessionId
          }
        });
      } else {
        dispatch({ 
          type: 'INITIALIZE_CART', 
          payload: initialState 
        });
      }

    } catch (error) {
      analytics.logError(error, { context: 'cartInitialization' });
      dispatch({ 
        type: 'SET_ERROR', 
        payload: new Error('Failed to load cart') 
      });
    }
  }, []);

  // Save cart to storage when it changes
  useEffect(() => {
    if (!state.isLoading) {
      saveCartToStorage({
        items: state.items,
        lastUpdated: state.lastUpdated,
        sessionId: state.sessionId
      })
    }
  }, [state.items, state.lastUpdated, state.sessionId, state.isLoading])

  const addItem = useCallback((item) => {
    const startTime = performance.now();
    
    dispatch({ 
      type: 'ADD_ITEM', 
      payload: {
        ...item,
        id: generateCartItemId()
      }
    });

    analytics.trackMetric('cart_operation_time', performance.now() - startTime);
  }, []);

  const removeItem = useCallback((itemId) => {
    dispatch({ type: 'REMOVE_ITEM', payload: itemId })
  }, [])

  const updateQuantity = useCallback((itemId, quantity) => {
    dispatch({ 
      type: 'UPDATE_QUANTITY', 
      payload: { itemId, quantity }
    })
  }, [])

  const clearCart = useCallback(() => {
    dispatch({ type: 'CLEAR_CART' })
    clearCartFromStorage()
  }, [])

  const bulkAdd = useCallback((items) => {
    dispatch({ type: 'BULK_ADD', payload: items })
  }, [])

  const bulkRemove = useCallback((itemIds) => {
    dispatch({ type: 'BULK_REMOVE', payload: itemIds })
  }, [])

  const setShippingAddress = useCallback((address) => {
    dispatch({ type: 'SET_SHIPPING_ADDRESS', payload: address })
  }, [])

  const setShippingOption = useCallback((option) => {
    dispatch({ type: 'SET_SHIPPING_OPTION', payload: option })
  }, [])

  const setPaymentStatus = useCallback((status) => {
    dispatch({ type: 'SET_PAYMENT_STATUS', payload: status })
  }, [])

  const setPaymentError = useCallback((error) => {
    dispatch({ type: 'SET_PAYMENT_ERROR', payload: error })
  }, [])

  const value = {
    ...state,
    addItem,
    removeItem,
    updateQuantity,
    clearCart,
    bulkAdd,
    bulkRemove,
    setShippingAddress,
    setShippingOption,
    setPaymentStatus,
    setPaymentError
  };

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.node.isRequired
}

