import PropTypes from 'prop-types'
import { THEME } from '../../constants/designSystem'
import { analytics } from '../../services/AnalyticsService'

const CarouselView = ({ 
  images, 
  currentIndex, 
  onImageChange,
  className = '' 
}) => {
  return (
    <div className={`relative w-full ${className}`}>
      <div className="relative aspect-[16/9] overflow-hidden rounded-lg">
        <img
          src={images[currentIndex]?.image}
          alt={images[currentIndex]?.alt}
          className="w-full h-full object-contain"
          onLoad={(e) => {
            const loadTime = e.timeStamp;
            analytics.trackMetric('image_load_time', loadTime);
          }}
          onError={(e) => {
            analytics.logError(new Error(`Failed to load image: ${images[currentIndex]?.image}`), {
              component: 'CarouselView',
              imageIndex: currentIndex
            });
          }}
        />
        
        {currentIndex > 0 && (
          <button
            onClick={() => onImageChange(currentIndex - 1)}
            className="absolute left-4 top-1/2 -translate-y-1/2 p-3
                     bg-black/20 hover:bg-black/40 rounded-full backdrop-blur-sm
                     text-white hover:text-gray-300 transition-all duration-300
                     touch-manipulation"
            aria-label="Previous image"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
        )}
        
        {currentIndex < images.length - 1 && (
          <button
            onClick={() => onImageChange(currentIndex + 1)}
            className="absolute right-4 top-1/2 -translate-y-1/2 p-3
                     bg-black/20 hover:bg-black/40 rounded-full backdrop-blur-sm
                     text-white hover:text-gray-300 transition-all duration-300
                     touch-manipulation"
            aria-label="Next image"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        )}
      </div>
      
      <div className="mt-4 flex justify-center items-center gap-2">
        {images.map((_, idx) => (
          <button
            key={idx}
            onClick={() => onImageChange(idx)}
            className={`w-2 h-2 rounded-full transition-all duration-300 ${
              idx === currentIndex 
                ? `${THEME.COLORS.BG.PRIMARY} w-4` 
                : `${THEME.COLORS.BG.MUTED} hover:${THEME.COLORS.BG.PRIMARY}`
            }`}
            aria-label={`Go to image ${idx + 1}`}
            aria-current={idx === currentIndex}
          />
        ))}
      </div>
    </div>
  )
}

CarouselView.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired,
  currentIndex: PropTypes.number.isRequired,
  onImageChange: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default CarouselView
