import BasePage from '../components/layout/BasePage'
import { ProductProvider } from '../context/ProductContext.jsx'
import { useProducts } from '../hooks/useProducts'
import { CartProvider } from '../context/CartContext'
import ProductGrid from '../components/shop/ProductGrid'
import ProductGridControls from '../components/shop/ProductGridControls'
import CartSummary from '../components/shop/CartSummary'

function ShopContent() {
  const { products } = useProducts()

  return (
    <div className="flex flex-col lg:flex-row gap-6">
      <div className="lg:w-3/4">
        <ProductGridControls />
        <ProductGrid products={products} />
      </div>
      <div className="lg:w-1/4 lg:sticky lg:top-4 lg:h-fit">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4">
          <CartSummary />
        </div>
      </div>
    </div>
  )
}

function ShopPage() {
  return (
    <BasePage title="Shop">
      <CartProvider>
        <ProductProvider>
          <ShopContent />
        </ProductProvider>
      </CartProvider>
    </BasePage>
  )
}

export default ShopPage
