import { createClient } from '@supabase/supabase-js';

let supabaseInstance = null;

export const getSupabaseClient = () => {
  if (!supabaseInstance) {
    const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
    const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
    supabaseInstance = createClient(supabaseUrl, supabaseAnonKey);
  }
  return supabaseInstance;
};

export const supabase = getSupabaseClient();

export const fetchTourPerformances = async () => {
  try {
    // First, let's check if we can connect and get table info
    const { data: tableInfo, error: tableError } = await supabase
      .from('performances')
      .select('count');
    
    if (tableError) {
      console.error('Error checking table:', tableError);
      throw new Error(`Table check failed: ${tableError.message}`);
    }

    console.log('Table info:', tableInfo);

    // Now try the actual query with more detailed error logging
    const { data, error } = await supabase
      .from('performances')
      .select('*')
      .order('date', { ascending: true });

    if (error) {
      console.error('Error details:', {
        message: error.message,
        details: error.details,
        hint: error.hint,
        code: error.code
      });
      throw error;
    }

  if (!data) {
    console.warn('No tour performances data received');
    return [];
  }

  console.log('Raw data from Supabase:', data);

  // Map the data to match TourItem expectations
  return data.map(performance => ({
    id: performance.id.toString(),
    date: performance.date,
    time: performance.time,
    venue: performance.venue,
    address: performance.address,
    city: performance.city,
    postCode: performance.post_code,
    country: performance.country,
    status: performance.status,
    eventType: performance.event_type,
    bookingLink: performance.booking_link || '#',
    priceNotes: performance.price_notes || '',
    venueNotes: performance.venue_notes || '',
    additionalInfo: performance.additional_info || ''
  }));
} catch (error) {
  console.error('Fetch error:', error);
  throw error;
}
}