import { useProducts } from '../../hooks/useProducts'
import { THEME } from '../../constants/designSystem'

function ProductSearch() {
  const { filters, setFilters } = useProducts()

  return (
    <div className="relative">
      <input
        type="search"
        value={filters.searchQuery}
        onChange={(e) => setFilters(prev => ({
          ...prev,
          searchQuery: e.target.value
        }))}
        placeholder="Search products..."
        className={`
          w-full px-4 py-2 rounded-lg
          border border-gray-300 dark:border-gray-700
          ${THEME.COLORS.BG.DEFAULT}
          focus:ring-2 focus:ring-primary-500 focus:border-transparent
        `}
        aria-label="Search products"
      />
    </div>
  )
}

export default ProductSearch
