// src/components/maintenance/MaintenanceMode.jsx
import { THEME, TYPOGRAPHY } from '../../constants/designSystem';
import { PreviewAccess } from './PreviewAccess';

export const MaintenanceMode = () => {
  return (
    <div className={`min-h-screen ${THEME.COLORS.BG.DEFAULT} flex flex-col items-center justify-center p-4`}>
      <div className="max-w-md text-center">
        <h1 className={`${TYPOGRAPHY.HEADING.H1} ${THEME.COLORS.TEXT.MUTED} mb-4`}>Website Under Construction</h1>
        <p className={`${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.MUTED} mb-8`}>
          Thank you for your interest in Jean Martyn&apos;s website. We&apos;re currently updating our site to bring you a better experience.
        </p>
        <div className={`${THEME.COLORS.TEXT.MUTED} text-sm mb-8`}>
          For inquiries, please check back soon.
        </div>
        <PreviewAccess />
      </div>
    </div>
  );
};