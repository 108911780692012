import { useState } from 'react'
import PropTypes from 'prop-types'
import { THEME } from '../../constants/designSystem'
import { SUPPORTED_COUNTRIES, validateAddress } from '../../utils/AddressUtils'

function AddressForm({ onSubmit, initialAddress = {} }) {
  const [address, setAddress] = useState({
    name: initialAddress.name || '',
    street: initialAddress.street || '',
    city: initialAddress.city || '',
    country: initialAddress.country || 'GB',
    postalCode: initialAddress.postalCode || ''
  })
  
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target
    setAddress(prev => ({
      ...prev,
      [name]: value
    }))
    
    // Clear error when field is modified
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: null
      }))
    }
  }

  const handleBlur = (e) => {
    const { name } = e.target
    setTouched(prev => ({
      ...prev,
      [name]: true
    }))
    
    // Validate single field on blur
    const { errors: validationErrors } = validateAddress({
      ...address,
      [name]: address[name]
    })
    
    if (validationErrors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: validationErrors[name]
      }))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    
    const validation = validateAddress(address)
    if (!validation.isValid) {
      setErrors(validation.errors)
      return
    }
    
    onSubmit(address)
  }

  const getFieldProps = (name) => ({
    id: name,
    name,
    value: address[name],
    onChange: handleChange,
    onBlur: handleBlur,
    'aria-invalid': errors[name] ? 'true' : 'false',
    'aria-required': 'true',
    className: `
      w-full rounded-lg border px-3 py-2
      ${errors[name] && touched[name] 
        ? 'border-red-500 dark:border-red-400' 
        : 'border-gray-300 dark:border-gray-700'}
      ${THEME.COLORS.BG.INPUT}
    `
  })

  return (
    <form onSubmit={handleSubmit} className="space-y-4" noValidate>
      <div>
        <label htmlFor="name" className="block mb-1">
          Full Name <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          inputMode="text"
          autoComplete="name"
          {...getFieldProps('name')}
          placeholder="John Doe"
        />
        {errors.name && touched.name && (
          <p className="mt-1 text-sm text-red-500" role="alert">
            {errors.name}
          </p>
        )}
      </div>

      <div>
        <label htmlFor="street" className="block mb-1">
          Street Address <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          inputMode="text"
          autoComplete="street-address"
          {...getFieldProps('street')}
          placeholder="123 Main St"
        />
        {errors.street && touched.street && (
          <p className="mt-1 text-sm text-red-500" role="alert">
            {errors.street}
          </p>
        )}
      </div>

      <div>
        <label htmlFor="city" className="block mb-1">
          City <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          inputMode="text"
          autoComplete="address-level2"
          {...getFieldProps('city')}
          placeholder="London"
        />
        {errors.city && touched.city && (
          <p className="mt-1 text-sm text-red-500" role="alert">
            {errors.city}
          </p>
        )}
      </div>

      <div>
        <label htmlFor="country" className="block mb-1">
          Country <span className="text-red-500">*</span>
        </label>
        <select {...getFieldProps('country')}>
          {SUPPORTED_COUNTRIES.map(country => (
            <option key={country.code} value={country.code}>
              {country.name}
            </option>
          ))}
        </select>
        {errors.country && touched.country && (
          <p className="mt-1 text-sm text-red-500" role="alert">
            {errors.country}
          </p>
        )}
      </div>

      <div>
        <label htmlFor="postalCode" className="block mb-1">
          Postal Code <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          inputMode="text"
          autoComplete="postal-code"
          {...getFieldProps('postalCode')}
          placeholder="SW1A 1AA"
        />
        {errors.postalCode && touched.postalCode && (
          <p className="mt-1 text-sm text-red-500" role="alert">
            {errors.postalCode}
          </p>
        )}
      </div>

      <button
        type="submit"
        className={`
          w-full px-4 py-2 rounded-lg
          ${THEME.COLORS.BG.PRIMARY}
          ${THEME.COLORS.TEXT.ON_PRIMARY}
          hover:opacity-90 transition-opacity
        `}
      >
        Continue to Shipping
      </button>
    </form>
  )
}

AddressForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialAddress: PropTypes.shape({
    name: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string
  })
}

export default AddressForm
