import { v4 as uuidv4 } from 'uuid'
import { StorageManager } from '../services/StorageManager'
import { analytics } from '../services/AnalyticsService'

const CART_STORAGE_KEY = 'jm-shop-cart'
const storageManager = new StorageManager(CART_STORAGE_KEY)
const MAX_QUANTITY_DEFAULT = 10

export const generateCartItemId = () => uuidv4()

export const generateSessionId = () => uuidv4()

export const validateQuantity = (quantity, maxQuantity = MAX_QUANTITY_DEFAULT) => {
  const parsedQuantity = parseInt(quantity)
  if (isNaN(parsedQuantity) || parsedQuantity < 1) return 1
  if (maxQuantity && parsedQuantity > maxQuantity) return maxQuantity
  return parsedQuantity
}

export const calculateCartTotal = (items) => {
  return items.reduce((total, item) => total + (item.price * item.quantity), 0)
}

export const saveCartToStorage = (cart) => {
  try {
    const success = storageManager.save({
      ...cart,
      lastUpdated: Date.now()
    });
    
    analytics.trackEvent('cart', 'save', success ? 'success' : 'failure');
    return success;
  } catch (error) {
    analytics.logError(error, { action: 'saveCart' });
    return false;
  }
}

export const loadCartFromStorage = () => {
  try {
    const start = performance.now();
    const cart = storageManager.load();
    
    analytics.trackMetric('cartLoadTime', performance.now() - start);
    analytics.trackEvent('cart', 'load', cart ? 'success' : 'empty');
    
    return cart;
  } catch (error) {
    analytics.logError(error, { action: 'loadCart' });
    return null;
  }
}

export const clearCartFromStorage = () => {
  try {
    localStorage.removeItem(CART_STORAGE_KEY);
    analytics.trackEvent('cart', 'clear', 'success');
    return true;
  } catch (error) {
    analytics.logError(error, { action: 'clearCart' });
    return false;
  }
}

export const subscribeToCartUpdates = (callback) => {
  storageManager.subscribe((data) => {
    if (data?.data) {
      callback(data.data);
    }
  });
}

export const mergeCartItems = (existingItems, newItems) => {
  const mergedItems = [...existingItems]
  
  newItems.forEach(newItem => {
    const existingIndex = mergedItems.findIndex(
      item => item.productId === newItem.productId && 
              item.variantId === newItem.variantId
    )
    
    if (existingIndex >= 0) {
      mergedItems[existingIndex].quantity = validateQuantity(
        mergedItems[existingIndex].quantity + newItem.quantity,
        mergedItems[existingIndex].maxQuantity
      )
    } else {
      mergedItems.push({
        ...newItem,
        id: generateCartItemId(),
        quantity: validateQuantity(newItem.quantity, newItem.maxQuantity)
      })
    }
  })
  
  return mergedItems
}
