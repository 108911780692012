import { loadStripe } from '@stripe/stripe-js'
import { analytics } from './AnalyticsService'

class PaymentService {
  constructor() {
    this.stripe = null
    this.elements = null
    this.initialized = false
  }

  async init() {
    if (this.initialized) return

    try {
      this.stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY)
      this.initialized = true
    } catch (error) {
      analytics.logError(error, { context: 'stripeInitialization' })
      throw new Error('Failed to initialize payment service')
    }
  }

  async createPaymentIntent(amount, currency, customerCountry) {
    if (!this.initialized) await this.init()

    try {
      const response = await fetch('/api/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount,
          currency,
          customerCountry, // For SCA determination
        }),
      })

      if (!response.ok) {
        throw new Error('Failed to create payment intent')
      }

      const { clientSecret } = await response.json()
      return clientSecret
    } catch (error) {
      analytics.logError(error, { context: 'paymentIntentCreation' })
      throw error
    }
  }

  async confirmPayment(clientSecret, paymentMethod) {
    if (!this.initialized) await this.init()

    try {
      const { error, paymentIntent } = await this.stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: paymentMethod,
        }
      )

      if (error) {
        analytics.logError(error, { context: 'paymentConfirmation' })
        throw error
      }

      return paymentIntent
    } catch (error) {
      analytics.logError(error, { context: 'paymentConfirmation' })
      throw error
    }
  }

  createElements(options = {}) {
    if (!this.stripe) {
      throw new Error('Stripe not initialized')
    }

    return this.stripe.elements(options)
  }
}

export const paymentService = new PaymentService()
