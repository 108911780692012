export const CONTENT_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  MIXED: 'mixed'
}

export const SECTION_WIDTHS = {
  NARROW: 'narrow',
  NORMAL: 'normal',
  WIDE: 'wide',
  FULL: 'full'
}
