import PropTypes from 'prop-types'
import { useState } from 'react'
import ResponsiveImage from '../ui/ResponsiveImage'
import ImageModal from './ImageModal'
import { cn } from '../../lib/utils'

const GalleryItem = ({ 
  image,
  alt,
  className,
  index,
  onImageClick 
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div 
        className={cn(
          "relative w-full h-full rounded-lg overflow-hidden cursor-pointer",
          "bg-gray-100 aspect-square group",
          "hover:shadow-lg transition-all duration-300 ease-in-out",
          "transform hover:scale-[1.02]",
          "motion-safe:animate-fadeIn",
          className
        )}
        onClick={() => {
          if (onImageClick) onImageClick(index)
          setIsModalOpen(true)
        }}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            setIsModalOpen(true)
          }
        }}
      >
        <ResponsiveImage
          src={image}
          alt={alt}
          loading="lazy"
          sizes="(max-width: 640px) 100vw, (max-width: 768px) 50vw, (max-width: 1024px) 33vw, 25vw"
        />
        <div 
          className={cn(
            "absolute inset-0 bg-black/40 flex items-center justify-center",
            "opacity-0 group-hover:opacity-100 transition-opacity duration-300",
            "backdrop-blur-[2px]"
          )}
        >
          <div className="text-white text-center p-4">
            <span className="sr-only">Click to view full image</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-8 w-8 mx-auto" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" 
              />
            </svg>
          </div>
        </div>
      </div>

      <ImageModal
        image={image}
        alt={alt}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )
}

GalleryItem.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  onImageClick: PropTypes.func
}

export default GalleryItem
