import { supabase } from './SupabaseService';

class AdminTourService {
  constructor() {
    this.tableName = 'performances';
  }

  async getAllTours() {
    const { data, error } = await supabase
      .from(this.tableName)
      .select('*')
      .order('date', { ascending: true });

    if (error) throw error;
    return data;
  }

  async getTourById(id) {
    const { data, error } = await supabase
      .from(this.tableName)
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  }

  async createTour(tourData) {
    const formattedData = {
      date: tourData.date,
      time: tourData.time,
      venue: tourData.venue,
      address: tourData.address,
      city: tourData.city,
      country: tourData.country,
      status: tourData.status,
      post_code: tourData.postCode,
      booking_link: tourData.bookingLink,
      price_notes: tourData.priceNotes,
      venue_notes: tourData.venueNotes,
      additional_info: tourData.additionalInfo,
      event_type: tourData.eventType
    };
  
    const { data, error } = await supabase
      .from(this.tableName)
      .insert([formattedData])
      .select()
      .single();
  
    if (error) throw error;
    return data;
  }

  async updateTour(id, tourData) {
    const formattedData = {
      date: tourData.date,
      time: tourData.time,
      venue: tourData.venue,
      address: tourData.address,
      city: tourData.city,
      country: tourData.country,
      status: tourData.status,
      post_code: tourData.postCode,
      booking_link: tourData.bookingLink,
      price_notes: tourData.priceNotes,
      venue_notes: tourData.venueNotes,
      additional_info: tourData.additionalInfo,
      event_type: tourData.eventType
    };

    const { data, error } = await supabase
      .from(this.tableName)
      .update(formattedData)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  }

  async deleteTour(id) {
    const { error } = await supabase
      .from(this.tableName)
      .delete()
      .eq('id', id);

    if (error) throw error;
    return true;
  }

  async updateTourStatus(id, status) {
    const { data, error } = await supabase
      .from(this.tableName)
      .update({ status })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  }
}

export const adminTourService = new AdminTourService();
