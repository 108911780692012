import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { THEME } from '../../constants/designSystem'
import { paymentService } from '../../services/PaymentService'
import { handlePaymentError, getSupportInfo, canRetryPayment } from '../../utils/PaymentUtils'

function PaymentForm({ amount, currency, countryCode, onPaymentComplete, onError }) {
  const [card, setCard] = useState(null)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    const initializePayment = async () => {
      try {
        await paymentService.init()
        const elements = paymentService.createElements({
          appearance: {
            theme: 'stripe',
            variables: {
              colorPrimary: '#0066cc',
              colorBackground: '#ffffff',
              colorText: '#1a1a1a',
              colorDanger: '#df1b41',
              fontFamily: 'system-ui, sans-serif',
              spacingUnit: '4px',
              borderRadius: '4px'
            }
          }
        })

        const cardElement = elements.create('card', {
          style: {
            base: {
              fontSize: '16px',
              color: '#1a1a1a',
              '::placeholder': {
                color: '#6b7280'
              }
            }
          }
        })

        cardElement.mount('#card-element')
        setCard(cardElement)

        cardElement.on('change', (event) => {
          setError(event.error ? event.error.message : null)
        })

      } catch (err) {
        const handledError = handlePaymentError(err)
        setError(handledError.message)
        onError(handledError)
      }
    }

    initializePayment()

    return () => {
      if (card) {
        card.unmount()
      }
    }
  }, [onError, card])

  const handleSubmit = async (event) => {
    event.preventDefault()
    
    if (!card) {
      setError('Payment form not initialized')
      return
    }

    setProcessing(true)
    setError(null)

    try {
      const clientSecret = await paymentService.createPaymentIntent(
        amount,
        currency,
        countryCode
      )

      const { paymentIntent, error: paymentError } = await paymentService.confirmPayment(
        clientSecret,
        { card }
      )

      if (paymentError) {
        throw paymentError
      }

      onPaymentComplete(paymentIntent)
    } catch (err) {
      const handledError = handlePaymentError(err)
      setError(handledError.message)
      onError(handledError)
    } finally {
      setProcessing(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="p-4 border rounded-lg">
        <div id="card-element" className="min-h-[48px]" />
      </div>

      {error && (
        <div 
          className="p-4 rounded-lg bg-red-50 border border-red-200 text-red-700 space-y-2"
          role="alert"
        >
          <p className="font-medium">{error.message}</p>
          <p className="text-sm">{error.action}</p>
          {error.contactSupport && (
            <div className="mt-2 text-sm border-t border-red-200 pt-2">
              <p className="font-medium">Need help?</p>
              <p>Contact our support team:</p>
              <ul className="mt-1 space-y-1">
                <li>Email: {getSupportInfo().email}</li>
                <li>Phone: {getSupportInfo().phone}</li>
                <li>Hours: {getSupportInfo().hours}</li>
              </ul>
            </div>
          )}
        </div>
      )}

      <div className="flex gap-4">
        <button
          type="submit"
          disabled={processing || !card || (error && !canRetryPayment(error))}
          className={`
            flex-1 px-4 py-2 rounded-lg
            ${THEME.COLORS.BG.PRIMARY}
            ${THEME.COLORS.TEXT.ON_PRIMARY}
            disabled:opacity-50
            hover:opacity-90 transition-opacity
          `}
        >
          {processing ? 'Processing...' : error ? 'Retry Payment' : 'Pay Now'}
        </button>
        
        {error && (
          <button
            type="button"
            onClick={() => window.location.href = '/shop'}
            className={`
              px-4 py-2 rounded-lg
              border border-gray-300 dark:border-gray-700
              hover:bg-gray-50 dark:hover:bg-gray-800
              transition-colors
            `}
          >
            Cancel
          </button>
        )}
      </div>
    </form>
  )
}

PaymentForm.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.oneOf(['GBP', 'EUR']).isRequired,
  countryCode: PropTypes.string.isRequired,
  onPaymentComplete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
}

export default PaymentForm
