import { useState } from 'react'
import ReactDOM from 'react-dom/client'
import { useCart } from '../../hooks/useCart'
import { formatPrice, calculateVAT, convertToEUR } from '../../utils/ProductUtils'
import { generateOrderReference, sendOrderConfirmationEmail } from '../../utils/OrderUtils'
import { handlePaymentError, getSupportInfo } from '../../utils/PaymentUtils'
import PaymentErrorBoundary from './PaymentErrorBoundary'
import { THEME } from '../../constants/designSystem'
import OrderConfirmation from './OrderConfirmation'
import Button from '../ui/Button'
import AddressForm from './AddressForm'
import ShippingOptions from './ShippingOptions'
import PaymentForm from './PaymentForm'

function CartSummary() {
  const { 
    items, 
    clearCart, 
    shippingAddress, 
    setShippingAddress,
    shippingOption,
    setShippingOption,
    setPaymentStatus,
    setPaymentError
  } = useCart()
  const [showAddressForm, setShowAddressForm] = useState(false)

  const subtotal = items.reduce((sum, item) => sum + (item.price * item.quantity), 0)
  const vat = calculateVAT(subtotal)
  const shippingCost = shippingOption?.price || 0
  const total = subtotal + vat + shippingCost
  const itemCount = items.reduce((count, item) => count + item.quantity, 0)

  if (items.length === 0) {
    return (
      <div 
        className="p-4 text-center"
        role="status"
        aria-label="Empty cart"
      >
        <p className={`${THEME.COLORS.TEXT.MUTED} mb-4`}>
          Your cart is empty
        </p>
        <Button
          variant="outline"
          size="sm"
          onClick={() => window.history.back()}
        >
          Continue Shopping
        </Button>
      </div>
    )
  }

  return (
    <div className="space-y-4 max-w-full overflow-x-hidden" role="region" aria-label="Cart summary">
      <div className="divide-y">
        {items.map(item => (
          <div 
            key={item.id}
            className="py-4 flex items-center justify-between"
          >
            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
              {item.image && (
                <img 
                  src={item.image} 
                  alt=""
                  className="w-16 h-16 object-cover rounded"
                />
              )}
              <div>
                <h3 className={`font-medium ${THEME.COLORS.TEXT.DEFAULT}`}>
                  {item.name}
                </h3>
                <p className={THEME.COLORS.TEXT.MUTED}>
                  Quantity: {item.quantity}
                </p>
              </div>
            </div>
            <div className="text-right">
              <p className="font-medium">
                {formatPrice(item.price * item.quantity)}
              </p>
              <p className={`text-sm ${THEME.COLORS.TEXT.MUTED}`}>
                {formatPrice(convertToEUR(item.price * item.quantity), 'EUR')}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="pt-4 border-t">
        <div className="space-y-2 mb-4">
          <div className="flex justify-between">
            <span className="font-medium">Subtotal ({itemCount} items)</span>
            <div className="text-right">
              <div>{formatPrice(subtotal)}</div>
              <div className={`text-sm ${THEME.COLORS.TEXT.MUTED}`}>
                {formatPrice(convertToEUR(subtotal), 'EUR')}
              </div>
            </div>
          </div>
          
          <div className="flex justify-between">
            <span className="font-medium">VAT (20%)</span>
            <div className="text-right">
              <div>{formatPrice(vat)}</div>
              <div className={`text-sm ${THEME.COLORS.TEXT.MUTED}`}>
                {formatPrice(convertToEUR(vat), 'EUR')}
              </div>
            </div>
          </div>
          
          <div className="flex justify-between pt-2 border-t">
            <span className="font-bold">Total</span>
            <div className="text-right">
              <div className="font-bold">{formatPrice(total)}</div>
              <div className={`text-sm ${THEME.COLORS.TEXT.MUTED}`}>
                {formatPrice(convertToEUR(total), 'EUR')}
              </div>
            </div>
          </div>
        </div>
        
        <div className="flex gap-4">
          <Button
            variant="outline"
            onClick={clearCart}
            className="w-full"
            aria-label="Clear cart"
          >
            Clear Cart
          </Button>
          {!showAddressForm ? (
            <Button
              variant="primary"
              className="w-full"
              onClick={() => setShowAddressForm(true)}
              aria-label="Proceed to checkout"
            >
              Checkout
            </Button>
          ) : (
            <div className="space-y-6">
              <div className="border-t pt-6">
                <h3 className="text-lg font-semibold mb-4">Shipping Address</h3>
                <AddressForm 
                  onSubmit={(address) => {
                    setShippingAddress(address)
                    setShippingOption(null) // Reset shipping option when address changes
                  }}
                  initialAddress={shippingAddress}
                />
              </div>
              
              {shippingAddress && (
                <div className="border-t pt-6">
                  <ShippingOptions
                    countryCode={shippingAddress.country}
                    selectedOption={shippingOption}
                    onSelect={setShippingOption}
                  />
                </div>
              )}

              {shippingOption && (
                <>
                  <div className="flex justify-between pt-4 border-t">
                    <span className="font-medium">Shipping</span>
                    <div className="text-right">
                      <div>{formatPrice(shippingOption.price)}</div>
                      <div className={`text-sm ${THEME.COLORS.TEXT.MUTED}`}>
                        {formatPrice(convertToEUR(shippingOption.price), 'EUR')}
                      </div>
                    </div>
                  </div>

                  <div className="border-t pt-6">
                    <h3 className="text-lg font-semibold mb-4">Payment</h3>
                    <PaymentErrorBoundary onReset={() => setPaymentError(null)}>
                      <PaymentForm
                      amount={total}
                      currency="GBP"
                      countryCode={shippingAddress.country}
                      onPaymentComplete={async (paymentIntent) => {
                        try {
                          const orderReference = generateOrderReference()
                          const orderDate = new Date()
                          
                          await sendOrderConfirmationEmail({
                            orderReference,
                            orderDate,
                            items,
                            shippingAddress,
                            shippingOption,
                            totalAmount: total,
                            vatAmount: vat,
                            paymentIntent
                          })

                          setPaymentStatus('success')
                          clearCart()

                          // Show order confirmation
                          const confirmationDialog = document.createElement('div')
                          confirmationDialog.className = 'fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50'
                          confirmationDialog.innerHTML = `
                            <div class="w-full max-w-2xl">
                              <div id="confirmation-content"></div>
                            </div>
                          `
                          document.body.appendChild(confirmationDialog)

                          const root = ReactDOM.createRoot(confirmationDialog.querySelector('#confirmation-content'))
                          root.render(
                            <OrderConfirmation
                              orderReference={orderReference}
                              orderDate={orderDate}
                              items={items}
                              shippingAddress={shippingAddress}
                              shippingOption={shippingOption}
                              totalAmount={total}
                              vatAmount={vat}
                              onClose={() => {
                                root.unmount()
                                confirmationDialog.remove()
                                window.location.href = '/shop'
                              }}
                            />
                          )
                        } catch (error) {
                          console.error('Order completion error:', error)
                          const handledError = handlePaymentError(error)
                          setPaymentError(handledError)
                          
                          // Show support contact for non-recoverable errors
                          if (!handledError.recoverable) {
                            const supportInfo = getSupportInfo()
                            const supportDialog = document.createElement('div')
                            supportDialog.className = 'fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50'
                            supportDialog.innerHTML = `
                              <div class="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-md w-full">
                                <h3 class="text-lg font-semibold mb-4">Need Help?</h3>
                                <p class="mb-4">Our support team is here to assist you:</p>
                                <ul class="space-y-2 mb-6">
                                  <li>Email: ${supportInfo.email}</li>
                                  <li>Phone: ${supportInfo.phone}</li>
                                  <li>Hours: ${supportInfo.hours}</li>
                                </ul>
                                <button 
                                  class="w-full px-4 py-2 rounded-lg bg-primary-600 text-white hover:bg-primary-700"
                                  onclick="this.closest('.fixed').remove()"
                                >
                                  Close
                                </button>
                              </div>
                            `
                            document.body.appendChild(supportDialog)
                          }
                        }
                      }}
                      onError={(error) => {
                        setPaymentError(error)
                      }}
                      />
                    </PaymentErrorBoundary>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CartSummary
