import PropTypes from 'prop-types';
import { SPACING, TYPOGRAPHY, THEME } from '../../../constants/designSystem';
import ContentNavigation from './ContentNavigation';

const ContentLayout = ({ children, title }) => {
  return (
    <div className={`${THEME.COLORS.BG.DEFAULT} min-h-screen`}>
      <div className={`${SPACING.DEFAULT} container mx-auto`}>
        <h1 className={`${TYPOGRAPHY.HEADING.H1} mb-6`}>{title}</h1>
        <div className="flex flex-col md:flex-row gap-6">
          <aside className="w-full md:w-64">
            <ContentNavigation />
          </aside>
          <main className="flex-1">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default ContentLayout;
