const UK_POSTCODE_REGEX = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i
const EU_POSTCODE_PATTERNS = {
  DE: /^[0-9]{5}$/,
  FR: /^[0-9]{5}$/,
  IT: /^[0-9]{5}$/,
  ES: /^[0-9]{5}$/,
  NL: /^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i
}

export const SUPPORTED_COUNTRIES = [
  { code: 'GB', name: 'United Kingdom' },
  { code: 'DE', name: 'Germany' },
  { code: 'FR', name: 'France' },
  { code: 'IT', name: 'Italy' },
  { code: 'ES', name: 'Spain' },
  { code: 'NL', name: 'Netherlands' }
]

export const validatePostalCode = (postalCode, countryCode) => {
  if (!postalCode || !countryCode) return false
  
  if (countryCode === 'GB') {
    return UK_POSTCODE_REGEX.test(postalCode.trim())
  }
  
  const pattern = EU_POSTCODE_PATTERNS[countryCode]
  return pattern ? pattern.test(postalCode.trim()) : true
}

export const validateAddress = (address) => {
  const errors = {}
  
  if (!address.name?.trim()) {
    errors.name = 'Name is required'
  }
  
  if (!address.street?.trim()) {
    errors.street = 'Street address is required'
  }
  
  if (!address.city?.trim()) {
    errors.city = 'City is required'
  }
  
  if (!address.country) {
    errors.country = 'Country is required'
  }
  
  if (!address.postalCode?.trim()) {
    errors.postalCode = 'Postal code is required'
  } else if (!validatePostalCode(address.postalCode, address.country)) {
    errors.postalCode = 'Invalid postal code format'
  }
  
  return {
    isValid: Object.keys(errors).length === 0,
    errors
  }
}
