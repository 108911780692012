/**
 * Email Service Module
 * 
 * Handles email sending functionality with proper error handling and configuration validation.
 * 
 * Required Environment Variables:
 * - VITE_SENDGRID_API_KEY: SendGrid API key for email sending
 * - VITE_CONTACT_EMAIL: Destination email address for contact form submissions
 * - VITE_FROM_EMAIL: Email address used as the sender
 * 
 * Security Considerations:
 * - All environment variables are validated at initialization
 * - No sensitive information is exposed in user-facing errors
 * - Proper error logging for debugging without exposing sensitive data
 * - Email content is properly sanitized before sending
 */

import sgMail from '@sendgrid/mail'

// Custom error types for different failure scenarios
class EmailConfigError extends Error {
  constructor(message) {
    super(message)
    this.name = 'EmailConfigError'
    this.userMessage = 'The email service is not properly configured. Please try again later.'
  }
}

class EmailSendError extends Error {
  constructor(message, originalError) {
    super(message)
    this.name = 'EmailSendError'
    this.originalError = originalError
    this.userMessage = 'Unable to send your message at this time. Please try again later.'
  }
}

const validateEmailConfig = () => {
  const requiredVars = [
    'VITE_SENDGRID_API_KEY',
    'VITE_CONTACT_EMAIL',
    'VITE_FROM_EMAIL'
  ]
  
  const missingVars = requiredVars.filter(varName => {
    const value = import.meta.env[varName]
    return !value || value === 'undefined' || value.trim() === ''
  })

  if (missingVars.length > 0) {
    throw new EmailConfigError(
      `Missing required environment variables: ${missingVars.join(', ')}`
    )
  }
}

let isInitialized = false;

const initializeSendGrid = () => {
  if (!isInitialized) {
    validateEmailConfig();
    sgMail.setApiKey(import.meta.env.VITE_SENDGRID_API_KEY);
    isInitialized = true;
  }
};

export { validateEmailConfig }

const sanitizeHtml = (str) => {
  return str.replace(/<[^>]*>/g, '');
}

const formatEmailContent = (formData) => {
  const { name, email, subject, message } = formData
  
  // Sanitize all user input
  const sanitizedName = sanitizeHtml(name)
  const sanitizedSubject = sanitizeHtml(subject)
  const sanitizedMessage = sanitizeHtml(message)
  
  return {
    to: import.meta.env.VITE_CONTACT_EMAIL,
    from: import.meta.env.VITE_FROM_EMAIL,
    replyTo: email,
    subject: `Website Contact: ${sanitizedSubject}`,
    text: `
Name: ${sanitizedName}
Email: ${email}
Subject: ${sanitizedSubject}

Message:
${sanitizedMessage}
    `,
    html: `
      <h2>New Contact Form Submission</h2>
      <p><strong>Name:</strong> ${sanitizedName}</p>
      <p><strong>Email:</strong> ${email}</p>
      <p><strong>Subject:</strong> ${sanitizedSubject}</p>
      <h3>Message:</h3>
      <p>${sanitizedMessage.replace(/\n/g, '<br>')}</p>
    `
  }
}

export const sendContactEmail = async (formData) => {
  try {
    // Initialize SendGrid only when needed
    initializeSendGrid();
    
    const emailContent = formatEmailContent(formData);
    const response = await sgMail.send(emailContent);
    const messageId = response?.[0]?.headers?.['x-message-id'] || 'unknown';
    return {
      success: true,
      messageId
    };
  } catch (error) {
    // Add development mode logging to help with debugging
    if (import.meta.env.DEV) {
      console.warn('SendGrid Error in Development:', {
        message: error.message,
        config: {
          apiKeyExists: !!import.meta.env.VITE_SENDGRID_API_KEY,
          contactEmailExists: !!import.meta.env.VITE_CONTACT_EMAIL,
          fromEmailExists: !!import.meta.env.VITE_FROM_EMAIL
        }
      });
    }

    // Rest of your error handling stays the same
    const errorDetails = {
      type: 'EmailSendError',
      message: error.message,
      originalError: error,
      timestamp: new Date().toISOString()
    };
    console.error('Email Service Error:', errorDetails);

    if (error instanceof EmailConfigError) {
      throw error;
    }
    throw new EmailSendError(
      'Failed to send email notification',
      error
    );
  }
};
