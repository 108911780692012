// src/components/maintenance/MaintenanceRoute.jsx
import PropTypes from 'prop-types';
import { MaintenanceMode } from './MaintenanceMode';

export const MaintenanceRoute = ({ children }) => {
  // Check if we're in development using import.meta.env
  const isPreviewAllowed = import.meta.env.DEV || 
                          localStorage.getItem('preview_access');

  if (!isPreviewAllowed) {
    return <MaintenanceMode />;
  }

  return children;
};

MaintenanceRoute.propTypes = {
  children: PropTypes.node.isRequired
};