import { analytics } from '../services/AnalyticsService'

export const PAYMENT_ERRORS = {
  card_error: {
    message: 'There was an issue with your card. Please check the details and try again.',
    action: 'Review your card details and try again',
    recoverable: true
  },
  validation_error: {
    message: 'Please check your payment information and try again.',
    action: 'Review all payment details',
    recoverable: true
  },
  processing_error: {
    message: 'There was an issue processing your payment. Please try again.',
    action: 'Click "Retry Payment" to try again',
    recoverable: true,
    retryDelay: 2000 // Wait 2s before allowing retry
  },
  authentication_error: {
    message: 'Payment authentication failed. Please try again.',
    action: 'Try payment again or use a different card',
    recoverable: true
  },
  rate_limit_error: {
    message: 'Too many payment attempts. Please wait a moment and try again.',
    action: 'Please wait before trying again',
    recoverable: true,
    retryDelay: 30000 // 30s cooldown
  },
  api_error: {
    message: 'Payment system temporarily unavailable. Please try again later.',
    action: 'Please try again in a few minutes',
    recoverable: false,
    contactSupport: true
  },
  invalid_request_error: {
    message: 'Invalid payment request. Please try again.',
    action: 'Try payment again',
    recoverable: true
  }
}

export const handlePaymentError = (error) => {
  analytics.logError(error, { 
    context: 'payment',
    errorType: error.type,
    errorCode: error.code
  })
  
  const errorConfig = PAYMENT_ERRORS[error.type] || {
    message: 'An unexpected error occurred. Please try again.',
    action: 'Try again or contact support',
    recoverable: true,
    contactSupport: true
  }
  
  return {
    ...errorConfig,
    code: error.code || 'unknown',
    originalError: error,
    timestamp: Date.now()
  }
}

export const canRetryPayment = (error) => {
  if (!error?.recoverable) return false
  
  const retryDelay = error.retryDelay || 0
  const timeSinceError = Date.now() - (error.timestamp || 0)
  
  return timeSinceError >= retryDelay
}

export const getSupportInfo = () => ({
  email: 'support@jeansmusic.com',
  phone: '+44 (0)20 1234 5678',
  hours: '9am-5pm GMT, Monday-Friday'
})

export const requiresSCA = (countryCode) => {
  // EEA countries require SCA
  const EEA_COUNTRIES = ['DE', 'FR', 'IT', 'ES', 'NL']
  return EEA_COUNTRIES.includes(countryCode)
}

export const formatStripeAmount = (amount, currency) => {
  // Stripe expects amounts in smallest currency unit (cents/pence)
  // JPY has no decimal places, others typically use 2
  const multiplier = currency === 'JPY' ? 1 : 100
  return Math.round(amount * multiplier)
}
