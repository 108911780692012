// src/components/home/GridItem.jsx
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ResponsiveImage from '../ui/ResponsiveImage';
import { CELL_TYPES } from './gridConstants';
import { 
  ANIMATION, 
  OPACITY, 
  TYPOGRAPHY, 
  THEME 
} from '../../constants/designSystem';

const GridItemContent = ({ title, description, type, isHovered }) => (
  <div className={`
    absolute inset-0 flex flex-col justify-end p-4 lg:p-6
    transition-all ${ANIMATION.DURATION.DEFAULT}
    ${isHovered ? 'translate-y-0' : 'translate-y-4'}
  `}>
    <h3 className={`
      ${THEME.COLORS.HOVER.TEXT.GRID}
      ${type === CELL_TYPES.LARGE ? TYPOGRAPHY.TITLE.LARGE : TYPOGRAPHY.TITLE.MEDIUM}
      mb-2 transition-opacity ${ANIMATION.DURATION.DEFAULT}
      ${isHovered ? OPACITY.FULL : OPACITY.NONE}
    `}>
      {title}
    </h3>
    {(type === CELL_TYPES.LARGE || type === CELL_TYPES.WIDE) && (
      <p className={`
        ${THEME.COLORS.TEXT.SECONDARY} ${TYPOGRAPHY.BODY.LARGE} max-w-xl
        transition-opacity ${ANIMATION.DURATION.DEFAULT}
        ${isHovered ? OPACITY.FULL : OPACITY.NONE}
      `}>
        {description}
      </p>
    )}
  </div>
);

const Overlay = ({ isHovered }) => (
  <>
    <div className={`
      absolute inset-0 ${THEME.COLORS.BG.OVERLAY}
      transition-opacity ${ANIMATION.DURATION.DEFAULT}
      ${isHovered ? OPACITY.LIGHT : OPACITY.NONE}
    `} />
    <div className={`
      absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent
      transition-opacity ${ANIMATION.DURATION.DEFAULT}
      ${isHovered ? OPACITY.FULL : OPACITY.NONE}
    `} />
  </>
);

const GridItem = ({ 
  image, 
  title, 
  description, 
  link, 
  type = CELL_TYPES.STANDARD 
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const getImageSizes = (type) => {
    return `
      (min-width: 1280px) ${type === CELL_TYPES.LARGE ? '50vw' : '25vw'},
      (min-width: 1024px) ${type === CELL_TYPES.LARGE ? '66vw' : '33vw'},
      (min-width: 768px) ${type === CELL_TYPES.LARGE ? '100vw' : '50vw'},
      100vw
    `;
  };

  return (
    <Link
      to={link}
      className="block absolute inset-0 w-full h-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="absolute inset-0">
        <ResponsiveImage
          src={image}
          alt={title}
          loading={type === CELL_TYPES.LARGE ? 'eager' : 'lazy'}
          className="h-full"
          sizes={getImageSizes(type)}
        />
      </div>
      
      <Overlay isHovered={isHovered} />
      <GridItemContent 
        title={title}
        description={description}
        type={type}
        isHovered={isHovered}
      />
    </Link>
  );
};

GridItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(CELL_TYPES))
};

GridItemContent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(CELL_TYPES)).isRequired,
  isHovered: PropTypes.bool.isRequired
};

Overlay.propTypes = {
  isHovered: PropTypes.bool.isRequired
};

export default GridItem;
