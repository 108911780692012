import { useState } from 'react';
import { useTheme } from '../../lib/theme';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import PropTypes from 'prop-types';
import ThemeToggle from '../ui/ThemeToggle';
import Button from '../ui/Button';
import { THEME, Z_INDEX, ANIMATION } from '../../constants/designSystem';

const NavLink = ({ to, children, onClick = () => {}, className = '' }) => (
  <Link
    to={to}
    className={`${THEME.COLORS.TEXT.MUTED} hover:text-gray-900 dark:hover:text-white ${ANIMATION.DURATION.DEFAULT} transition-all border-b-2 border-transparent hover:border-gray-900 dark:hover:border-white ${className}`}
    onClick={onClick}
  >
    {children}
  </Link>
);

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const navigationLinks = [
  { to: '/tour', label: 'Tour' },
  { to: '/shop', label: 'Shop' },
  { to: '/gallery', label: 'Gallery' }, 
  { to: '/discography', label: 'Discography' },
  { to: '/awards', label: 'Awards' },
  { to: '/press', label: 'Press' },
  { to: '/history', label: 'History' },
  { to: '/contact', label: 'Contact' },
];

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { theme } = useTheme();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className={`${THEME.COLORS.BG.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT} relative ${Z_INDEX.OVERLAY}`}>
      {/* Main navigation */}
      <nav className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center">
          {/* Mobile menu button on far left */}
          <Button
            variant="ghost"
            size="sm"
            className="sm:hidden"
            onClick={handleMenuToggle}
            aria-expanded={isMenuOpen}
          >
            <span className="sr-only">
              {isMenuOpen ? 'Close main menu' : 'Open main menu'}
            </span>
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </Button>

          {/* Navigation links in center */}
          <div className="hidden sm:flex sm:space-x-8 sm:mx-auto">
            {navigationLinks.map((link) => (
              <NavLink key={link.to} to={link.to}>
                {link.label}
              </NavLink>
            ))}
          </div>

          <div className="flex items-center gap-4">
            <ThemeToggle />
            <Link 
              to="/" 
              className="flex items-center">
              <img 
                src={`/images/brand/${theme === 'dark' ? 'jean-logo-dark' : 'jean-logo'}.png`}
                alt="Jean Martyn Logo" 
                className="h-12 w-auto"
              />
            </Link>
          </div>
        </div>

        {/* Mobile menu dropdown */}
        {isMenuOpen && (
          <div className={`absolute left-0 right-0 top-16 ${THEME.COLORS.BG.DEFAULT} ${THEME.COLORS.BORDER.DEFAULT} shadow-lg sm:hidden ${Z_INDEX.MODAL}`}>
            <div className="px-4 py-2">
              {navigationLinks.map((link) => (
                <NavLink 
                  key={link.to} 
                  to={link.to}
                  onClick={closeMenu}
                  className="block py-3 px-2 hover:bg-gray-800 rounded-md"
                >
                  {link.label}
                </NavLink>
              ))}
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}

export default Header;
