import { cn } from "../../lib/utils"
import PropTypes from 'prop-types'

function TourItemSkeleton({ className }) {
  return (
    <div className={cn(
      "bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 rounded-lg p-4 shadow-sm",
      "animate-pulse",
      className
    )}>
      <div className="flex flex-col gap-4">
        {/* Header: Date, Time, and Status */}
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start">
          <div className="flex gap-2 items-baseline">
            <div className="h-5 w-32 bg-slate-700 rounded"></div>
            <div className="h-4 w-16 bg-slate-700 rounded"></div>
          </div>
          <div className="h-4 w-20 bg-slate-700 rounded mt-2 sm:mt-0"></div>
        </div>

        {/* Venue and Location */}
        <div className="space-y-2">
          <div className="h-5 w-48 bg-slate-700 rounded"></div>
          <div className="h-4 w-64 bg-slate-700 rounded"></div>
          <div className="h-4 w-56 bg-slate-700 rounded"></div>
        </div>

        {/* Event Details Box */}
        <div className="bg-blue-900/20 border border-blue-800 rounded-lg p-3">
          <div className="space-y-2">
            <div className="h-4 w-32 bg-slate-700 rounded"></div>
            <div className="h-4 w-48 bg-slate-700 rounded"></div>
            <div className="h-4 w-40 bg-slate-700 rounded"></div>
          </div>
        </div>

        {/* Booking Button */}
        <div>
          <div className="h-10 w-32 bg-slate-700 rounded sm:h-9"></div>
        </div>
      </div>
    </div>
  )
}

TourItemSkeleton.propTypes = {
  className: PropTypes.string
}

export default TourItemSkeleton
