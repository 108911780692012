import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { cn } from '../../lib/utils'
import { THEME } from '../../constants/designSystem'

const Button = forwardRef(({
  className,
  variant = 'primary',
  size = 'default',
  disabled = false,
  children,
  ...props
}, ref) => {
  const baseStyles = 'inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50'
  
  const variants = {
    primary: `${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY} ${THEME.COLORS.HOVER.BG.PRIMARY}`,
    secondary: `${THEME.COLORS.BG.SECONDARY} ${THEME.COLORS.TEXT.ON_SECONDARY} ${THEME.COLORS.HOVER.BG.SECONDARY}`,
    outline: `border-2 ${THEME.COLORS.BORDER.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT} hover:bg-surface-muted dark:hover:bg-surface-muted-dark`,
    ghost: `${THEME.COLORS.TEXT.DEFAULT} hover:bg-surface-muted dark:hover:bg-surface-muted-dark`
  }

  const sizes = {
    default: 'h-10 px-4 py-2',
    sm: 'h-8 px-3 text-sm',
    lg: 'h-12 px-6 text-lg'
  }

  return (
    <button
      className={cn(
        baseStyles,
        variants[variant],
        sizes[size],
        className
      )}
      ref={ref}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
})

Button.displayName = 'Button'

Button.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'outline', 'ghost']),
  size: PropTypes.oneOf(['default', 'sm', 'lg']),
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default Button
