import BasePage from '../components/layout/BasePage'

function HistoryPage() {
  return (
    <BasePage title="History">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <p>History information will go here.</p>
      </div>
    </BasePage>
  )
}

export default HistoryPage