import { useState } from 'react';
import PropTypes from 'prop-types';
import { THEME } from '../../constants/designSystem';

const ImageMetadataForm = ({ image, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    altText: image.alt_text || '',
    caption: image.caption || ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="altText" className="block text-sm font-medium mb-1">
          Alt Text
        </label>
        <input
          type="text"
          id="altText"
          value={formData.altText}
          onChange={(e) => setFormData(prev => ({ ...prev, altText: e.target.value }))}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
          required
        />
      </div>

      <div>
        <label htmlFor="caption" className="block text-sm font-medium mb-1">
          Caption
        </label>
        <textarea
          id="caption"
          value={formData.caption}
          onChange={(e) => setFormData(prev => ({ ...prev, caption: e.target.value }))}
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
          rows={3}
        />
      </div>

      <div className="flex justify-end gap-3">
        <button
          type="button"
          onClick={onCancel}
          className={`px-4 py-2 rounded-lg ${THEME.COLORS.BG.MUTED} ${THEME.COLORS.TEXT.DEFAULT}`}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`px-4 py-2 rounded-lg ${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY}`}
        >
          Save Changes
        </button>
      </div>
    </form>
  );
};

ImageMetadataForm.propTypes = {
  image: PropTypes.shape({
    alt_text: PropTypes.string,
    caption: PropTypes.string
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ImageMetadataForm;
