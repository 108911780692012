import { DashboardCard } from './DashboardCard';
import { THEME, TYPOGRAPHY } from '../../../constants/designSystem';

// This would typically come from your data service
const mockMetrics = {
  totalProducts: 24,
  activeGalleries: 3,
  upcomingTours: 5,
  recentPressReleases: 2
};

export const StatusOverview = () => {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4">
      <DashboardCard>
        <div className="text-center">
          <p className={`${TYPOGRAPHY.HEADING.H2} ${THEME.COLORS.TEXT.DEFAULT} mb-2`}>
            {mockMetrics.totalProducts}
          </p>
          <p className={`${THEME.COLORS.TEXT.MUTED}`}>Products</p>
        </div>
      </DashboardCard>
      
      <DashboardCard>
        <div className="text-center">
          <p className={`${TYPOGRAPHY.HEADING.H2} ${THEME.COLORS.TEXT.DEFAULT} mb-2`}>
            {mockMetrics.activeGalleries}
          </p>
          <p className={`${THEME.COLORS.TEXT.MUTED}`}>Active Galleries</p>
        </div>
      </DashboardCard>
      
      <DashboardCard>
        <div className="text-center">
          <p className={`${TYPOGRAPHY.HEADING.H2} ${THEME.COLORS.TEXT.DEFAULT} mb-2`}>
            {mockMetrics.upcomingTours}
          </p>
          <p className={`${THEME.COLORS.TEXT.MUTED}`}>Upcoming Tours</p>
        </div>
      </DashboardCard>
      
      <DashboardCard>
        <div className="text-center">
          <p className={`${TYPOGRAPHY.HEADING.H2} ${THEME.COLORS.TEXT.DEFAULT} mb-2`}>
            {mockMetrics.recentPressReleases}
          </p>
          <p className={`${THEME.COLORS.TEXT.MUTED}`}>Recent Press Releases</p>
        </div>
      </DashboardCard>
    </div>
  );
};
