import BasePage from '../components/layout/BasePage'

function PressPage() {
  return (
    <BasePage title="Press">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <p>Press information will go here.</p>
      </div>
    </BasePage>
  )
}

export default PressPage