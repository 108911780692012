// src/components/home/gridConstants.js
import { GRID, ASPECT_RATIOS } from '../../constants/designSystem';

/**
 * @typedef {Object} GridItemType
 * @property {string} id - Unique identifier
 * @property {string} title - Display title
 * @property {string} description - Item description
 * @property {string} image - Image URL
 * @property {string} link - Navigation link
 * @property {CELL_TYPES} type - Grid cell type
 */

export const CELL_TYPES = {
  TALL: 'tall',    // 4x5
  SHORT: 'short',      // 2x1
  SQUARE: 'square' // 1x1
};

/**
 * Maps cell types to their grid spans
 * @type {Object.<string, string>}
 */
export const CELL_SPANS = {
  [CELL_TYPES.TALL]: GRID.SPANS.TALL,
  [CELL_TYPES.SHORT]: GRID.SPANS.SHORT,
  [CELL_TYPES.SQUARE]: GRID.SPANS.SQUARE
};

/**
 * Maps cell types to aspect ratios
 * @type {Object.<string, string>}
 */
export const CELL_ASPECT_RATIOS = {
  [CELL_TYPES.TALL]: ASPECT_RATIOS.TALL,
  [CELL_TYPES.SHORT]: ASPECT_RATIOS.SHORT,
  [CELL_TYPES.SQUARE]: ASPECT_RATIOS.SQUARE
};

/** @type {GridItemType[]} */

export const defaultGridItems = [
  {
    id: 'awards',
    title: 'Awards',
    description: 'Recognition and achievements throughout an illustrious career',
    image: '/images/homepage/awards.jpg',
    link: '/awards',
    type: CELL_TYPES.TALL
  },
  {
    id: 'gallery',
    title: 'Gallery',
    description: 'A visual journey through performances and memories',
    image: '/images/homepage/gallery.jpg',
    link: '/gallery',
    type: CELL_TYPES.SQUARE
  },
  {
    id: 'history',
    title: 'History',
    description: 'Musical journey and biography',
    image: '/images/homepage/history.jpg',
    link: '/history',
    type: CELL_TYPES.TALL
  },
  {
    id: 'tour',
    title: 'Tour',
    description: 'Times and Places Jean will be',
    image: '/images/homepage/tour.jpg',
    link: '/tour',
    type: CELL_TYPES.TALL
  },
  {
    id: 'discography',
    title: 'Discography',
    description: 'Albums and recordings that have touched hearts worldwide',
    image: '/images/homepage/discography.jpg',
    link: '/discography',
    type: CELL_TYPES.SHORT
  },
  {
    id: 'shop',
    title: 'Shop',
    description: 'Music, merchandise, and more',
    image: '/images/homepage/shop.jpg',
    link: '/shop',
    type: CELL_TYPES.TALL
  },
  {
    id: 'press',
    title: 'Press',
    description: 'News and media coverage',
    image: '/images/homepage/press.jpg',
    link: '/press',
    type: CELL_TYPES.SQUARE
  },
  {
    id: 'contact',
    title: 'Contact',
    description: 'Get in touch for bookings and inquiries',
    image: '/images/homepage/contact.jpg',
    link: '/contact',
    type: CELL_TYPES.SQUARE
  }
];
