import { THEME } from '../constants/designSystem'

const LOW_STOCK_THRESHOLD = 5
import placeholderImage from '../assets/images/shop/placeholder.jpg'
const IMAGE_FALLBACK = placeholderImage
const SUPPORTED_IMAGE_FORMATS = ['webp', 'jpg', 'png']

/**
 * Format price with currency symbol
 * @param {number} price
 * @returns {string}
 */
const VAT_RATE = 0.20 // 20% UK VAT rate
const EUR_CONVERSION_RATE = 1.16 // Example fixed rate, in production this should come from an API

export const formatPrice = (price, currency = 'GBP') => {
  const formatConfig = {
    GBP: { locale: 'en-GB', currency: 'GBP' },
    EUR: { locale: 'de-DE', currency: 'EUR' }
  }
  
  const config = formatConfig[currency] || formatConfig.GBP
  return new Intl.NumberFormat(config.locale, {
    style: 'currency',
    currency: config.currency
  }).format(price)
}

export const convertToEUR = (gbpPrice) => {
  return gbpPrice * EUR_CONVERSION_RATE
}

export const calculateVAT = (price) => {
  return price * VAT_RATE
}

/**
 * Calculate final price including variants
 * @param {import('../types/ProductTypes').Product} product
 * @param {string} [variantId]
 * @returns {number}
 */
export const calculatePrice = (product, variantId, quantity = 1) => {
  let basePrice = product.price
  
  if (variantId && product.variants) {
    const variant = product.variants.find(v => v.id === variantId)
    if (variant) {
      basePrice = variant.price
    }
  }

  // Apply quantity discounts if available
  if (product.quantityDiscounts && quantity > 1) {
    const discount = product.quantityDiscounts.find(d => quantity >= d.quantity)
    if (discount) {
      basePrice = basePrice * (1 - discount.percentage / 100)
    }
  }

  return basePrice * quantity
}

export const getOptimizedImageUrl = (imageUrl, size = 'medium') => {
  if (!imageUrl) return IMAGE_FALLBACK
  
  // Try WebP first, then fall back to original format
  for (const format of SUPPORTED_IMAGE_FORMATS) {
    const optimizedUrl = `${imageUrl.split('.')[0]}_${size}.${format}`
    // In real implementation, check if optimized version exists
    if (optimizedUrl) return optimizedUrl
  }
  
  return imageUrl
}

export const getVariantLabel = (variant) => {
  const labels = []
  if (variant.size) labels.push(variant.size)
  if (variant.color) labels.push(variant.color)
  return labels.join(' - ')
}

/**
 * Get stock status text and class
 * @param {number} stockLevel
 * @returns {{ text: string, className: string }}
 */
export const getStockStatus = (stockLevel) => {
  if (stockLevel <= 0) {
    return { 
      text: 'Out of Stock', 
      className: 'text-red-600 dark:text-red-400' 
    }
  }
  if (stockLevel <= LOW_STOCK_THRESHOLD) {
    return { 
      text: `Low Stock (${stockLevel} left)`, 
      className: 'text-amber-600 dark:text-amber-400' 
    }
  }
  return { 
    text: 'In Stock', 
    className: 'text-green-600 dark:text-green-400' 
  }
}

/**
 * Filter products based on criteria
 * @param {import('../types/ProductTypes').Product[]} products
 * @param {import('../types/ProductTypes').ProductFilters} filters
 * @returns {import('../types/ProductTypes').Product[]}
 */
export const filterProducts = (products, filters) => {
  return products.filter(product => {
    // Category filter
    if (filters.categories.length > 0 && 
        !product.categories.some(c => filters.categories.includes(c))) {
      return false
    }
    
    // Stock filter
    if (filters.inStockOnly && !product.inStock) {
      return false
    }
    
    // Search filter
    if (filters.searchQuery) {
      const query = filters.searchQuery.toLowerCase()
      const searchText = `
        ${product.name} 
        ${product.description} 
        ${product.tags?.join(' ') || ''}
      `.toLowerCase()
      
      return searchText.includes(query)
    }
    
    return true
  })
}

/**
 * Sort products based on criteria
 * @param {import('../types/ProductTypes').Product[]} products
 * @param {import('../types/ProductTypes').ProductSort} sort
 * @returns {import('../types/ProductTypes').Product[]}
 */
export const sortProducts = (products, sort) => {
  const sorted = [...products]
  
  sorted.sort((a, b) => {
    const aValue = a[sort.field]
    const bValue = b[sort.field]
    
    if (typeof aValue === 'string') {
      return sort.direction === 'asc' 
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue)
    }
    
    return sort.direction === 'asc' 
      ? aValue - bValue 
      : bValue - aValue
  })
  
  return sorted
}

/**
 * Get appropriate class names for product grid based on view mode
 * @param {'grid' | 'list'} viewMode
 * @returns {string}
 */
export const getProductGridClasses = (viewMode) => {
  const baseClasses = 'w-full gap-4 animate-fadeIn'
  
  if (viewMode === 'list') {
    return `${baseClasses} flex flex-col`
  }
  
  return `${baseClasses} grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8`
}

/**
 * Get appropriate class names for product card based on view mode
 * @param {'grid' | 'list'} viewMode
 * @returns {string}
 */
export const getProductCardClasses = (viewMode) => {
  const baseClasses = `
    relative overflow-hidden rounded-lg shadow-md 
    ${THEME.COLORS.BG.DEFAULT} 
    transition-transform duration-200 
    hover:shadow-lg hover:scale-[1.02]
  `
  
  if (viewMode === 'list') {
    return `${baseClasses} flex gap-4`
  }
  
  return baseClasses
}
