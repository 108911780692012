import BasePage from '../components/layout/BasePage'
import ContactForm from '../components/forms/ContactForm'
import { TYPOGRAPHY, THEME } from '../constants/designSystem'

function ContactPage() {
  return (
    <BasePage title="Contact">
      <div className="max-w-4xl mx-auto">
        <p className={`mb-6 ${TYPOGRAPHY.BODY.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT}`}>
          Get in touch with Jean Martyn&apos;s team using the form below.
        </p>
        <ContactForm />
      </div>
    </BasePage>
  )
}

export default ContactPage
