import PropTypes from 'prop-types'
import Header from './Header'
import Footer from './Footer'
import { THEME } from '../../constants/designSystem'
import { Outlet } from 'react-router-dom'
import UnderConstructionBanner from './UnderConstructionBanner'

function MainLayout({ children }) {
  return (
    <div className={`flex min-h-screen flex-col antialiased ${THEME.COLORS.BG.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT} transition-theme duration-theme ease-theme`}>
      <Header />
      <UnderConstructionBanner />
      <main className={`flex-1 ${THEME.COLORS.BG.MUTED}`}>
        <div className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8">
        {children || <Outlet />}
        </div>
      </main>
      <Footer />
    </div>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node
}

export default MainLayout
