import { analytics, PRIORITY } from '../services/AnalyticsService'

export const createTrackEvent = () => (eventName, data) => {
  // Initialize analytics if not already done
  if (!analytics.initialized) {
    analytics.init()
  }
  
  // Map eventName and data to analytics service format
  analytics.trackEvent(
    'product_system',    // category
    eventName,          // action
    null,              // label
    data,              // value
    PRIORITY.MEDIUM    // priority - using the proper constant
  )
}
