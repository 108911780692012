import { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const ThemeContext = createContext()

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(() => {
    try {
      // Check localStorage first
      const stored = localStorage.getItem('theme')
      if (stored) return stored
      
      // Then check system preference
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark'
      }
    } catch (error) {
      console.warn('Failed to get theme preference:', error)
    }
    
    return 'light' // Default theme
  })

  useEffect(() => {
    const root = window.document.documentElement
    // Start transition
    root.classList.add('transitioning-theme')
    // Remove old theme and add new one
    root.classList.remove('light', 'dark')
    root.classList.add(theme)
    // Force a repaint to ensure theme changes are applied
    document.body.style.display = 'none'
    document.body.offsetHeight // Force reflow
    document.body.style.display = ''
    
    localStorage.setItem('theme', theme)
    // Remove transition class after animation completes
    const timer = setTimeout(() => {
      root.classList.remove('transitioning-theme')
    }, 200) // Match duration with Tailwind config
    return () => clearTimeout(timer)
  }, [theme])

  // Listen for system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    const handleChange = (e) => {
      const systemTheme = e.matches ? 'dark' : 'light'
      // Only update if user hasn't set a preference
      if (!localStorage.getItem('theme')) {
        setTheme(systemTheme)
      }
    }

    mediaQuery.addEventListener('change', handleChange)
    return () => mediaQuery.removeEventListener('change', handleChange)
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useTheme() {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}
