import { useProducts } from '../../hooks/useProducts'
import { THEME } from '../../constants/designSystem'

function ProductFilters() {
  const { filters, setFilters, products } = useProducts()

  // Get unique categories from all products
  const categories = [...new Set(products.flatMap(p => p.categories))]

  const handleCategoryChange = (category) => {
    setFilters(prev => ({
      ...prev,
      categories: prev.categories.includes(category)
        ? prev.categories.filter(c => c !== category)
        : [...prev.categories, category]
    }))
  }

  return (
    <div className="space-y-4">
      <div>
        <h3 className={`font-semibold ${THEME.COLORS.TEXT.DEFAULT}`}>Filters</h3>
        <div className="mt-2">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={filters.inStockOnly}
              onChange={(e) => setFilters(prev => ({
                ...prev,
                inStockOnly: e.target.checked
              }))}
              className="rounded border-gray-300"
            />
            <span className={THEME.COLORS.TEXT.DEFAULT}>In Stock Only</span>
          </label>
        </div>
      </div>

      <div>
        <h4 className={`font-medium ${THEME.COLORS.TEXT.DEFAULT}`}>Categories</h4>
        <div className="space-y-2 mt-2">
          {categories.map(category => (
            <label key={category} className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={filters.categories.includes(category)}
                onChange={() => handleCategoryChange(category)}
                className="rounded border-gray-300"
              />
              <span className={THEME.COLORS.TEXT.DEFAULT}>
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </span>
            </label>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductFilters
