import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MainLayout from './components/layout/MainLayout';
import { AdminLayout } from './components/layout/AdminLayout';
import { LoginForm } from './components/auth/LoginForm';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { MaintenanceRoute } from './components/maintenance/MaintenanceRoute';
import { AuthProvider } from './contexts/AuthContext';
import HomePage from './pages/HomePage';
import AwardsPage from './pages/AwardsPage';
import ShopPage from './pages/ShopPage';
import GalleryPage from './pages/GalleryPage';
import HistoryPage from './pages/HistoryPage';
import DiscographyPage from './pages/DiscographyPage';
import PressPage from './pages/PressPage';
import ContactPage from './pages/ContactPage';
import TourPage from './pages/TourPage';
import DashboardPage from './pages/admin/DashboardPage';
import AdminTourPage from './pages/admin/content/AdminTourPage';
import AdminGalleryPage from './pages/admin/content/AdminGalleryPage';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router basename="/">
          <Routes>
            {/* Public Routes wrapped in MaintenanceRoute */}
            <Route element={
              <MaintenanceRoute>
                <MainLayout />
              </MaintenanceRoute>
            }>
              <Route path="/" element={<HomePage />} />
              <Route path="/awards" element={<AwardsPage />} />
              <Route path="/shop" element={<ShopPage />} />
              <Route path="/gallery" element={<GalleryPage />} />
              <Route path="/history" element={<HistoryPage />} />
              <Route path="/discography" element={<DiscographyPage />} />
              <Route path="/press" element={<PressPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/tour" element={<TourPage />} />
            </Route>

            {/* Admin Routes - unchanged */}
            <Route path="/admin">
              <Route path="login" element={<LoginForm />} />
              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <AdminLayout>
                      <DashboardPage />
                    </AdminLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="tour"
                element={
                  <ProtectedRoute>
                    <AdminLayout>
                      <AdminTourPage />
                    </AdminLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="gallery"
                element={
                  <ProtectedRoute>
                    <AdminLayout>
                      <AdminGalleryPage />
                    </AdminLayout>
                  </ProtectedRoute>
                }
              />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;