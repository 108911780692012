import PropTypes from 'prop-types'
import { cn } from '../../lib/utils'
import { TYPOGRAPHY } from '../../constants/designSystem'

const FormMessage = ({ type = 'success', message }) => {
  const baseStyles = cn(
    "p-4 mb-4 rounded-md",
    TYPOGRAPHY.BODY.DEFAULT,
    "flex items-center"
  )

  const styles = {
    success: "bg-green-50 text-green-800 border border-green-200",
    error: "bg-red-50 text-red-800 border border-red-200",
  }

  if (!message) return null

  return (
    <div 
      className={cn(baseStyles, styles[type])}
      role="status"
      aria-live="polite"
      aria-atomic="true"
    >
      {message}
    </div>
  )
}

FormMessage.propTypes = {
  type: PropTypes.oneOf(['success', 'error']),
  message: PropTypes.string
}

export default FormMessage
