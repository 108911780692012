import { AdminNavItem } from './AdminNavItem';
import { THEME, TYPOGRAPHY } from '../../constants/designSystem';
import {
  HomeIcon,
  PhotoIcon,
  ShoppingBagIcon,
  CalendarIcon,
  NewspaperIcon,
  ChartBarIcon
} from '@heroicons/react/24/outline';

const navigation = [
  { name: 'Dashboard', to: '/admin/dashboard', icon: HomeIcon },
  { 
    section: 'Content',
    items: [
      { name: 'Gallery', to: '/admin/gallery', icon: PhotoIcon },
      { name: 'Shop', to: '/admin/shop', icon: ShoppingBagIcon },
      { name: 'Tour Dates', to: '/admin/tour', icon: CalendarIcon },
      { name: 'Press', to: '/admin/press', icon: NewspaperIcon },
    ]
  },
  {
    section: 'Analytics',
    items: [
      { name: 'Performance', to: '/admin/performance', icon: ChartBarIcon },
    ]
  }
];

export const AdminNav = () => {
  return (
    <nav className="space-y-6">
      {navigation.map((item) => (
        <div key={item.section || item.name}>
          {item.section ? (
            <div>
              <h3 className={`${TYPOGRAPHY.BODY.SMALL} ${THEME.COLORS.TEXT.MUTED} uppercase tracking-wider font-medium px-4 mb-2`}>
                {item.section}
              </h3>
              <div className="space-y-1">
                {item.items.map((subItem) => (
                  <AdminNavItem
                    key={subItem.name}
                    to={subItem.to}
                    icon={subItem.icon}
                  >
                    {subItem.name}
                  </AdminNavItem>
                ))}
              </div>
            </div>
          ) : (
            <AdminNavItem
              to={item.to}
              icon={item.icon}
            >
              {item.name}
            </AdminNavItem>
          )}
        </div>
      ))}
    </nav>
  );
};
