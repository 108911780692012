import BasePage from '../components/layout/BasePage'

function DiscographyPage() {
  return (
    <BasePage title="Discography">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <p>Discography information will go here.</p>
      </div>
    </BasePage>
  )
}

export default DiscographyPage