import PropTypes from 'prop-types'
import { useProducts } from '../../hooks/useProducts'
import { getProductGridClasses } from '../../utils/ProductUtils'
import ProductCard from './ProductCard'
import ProductSkeleton from './ProductSkeleton'
import { useEffect, useState, useRef, useCallback } from 'react'
import { analytics } from '../../services/AnalyticsService'

const ITEMS_PER_PAGE = 12

function ProductGrid({ products }) {
  const [visibleProducts, setVisibleProducts] = useState([])
  const [page, setPage] = useState(1)
  const loadingRef = useRef(null)
  const startTime = useRef(performance.now())
  const { isLoading, error, viewMode, trackEvent, refetch } = useProducts()
  const loadStartTime = useRef(performance.now())
  
  const loadMoreProducts = useCallback(() => {
    const start = (page - 1) * ITEMS_PER_PAGE
    const end = page * ITEMS_PER_PAGE
    const newProducts = products.slice(start, end)
    
    setVisibleProducts(prev => [...prev, ...newProducts])
    setPage(prev => prev + 1)
    
    // Track loading performance
    analytics.trackMetric('product_load_time', performance.now() - startTime.current)
  }, [page, products])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && visibleProducts.length < products.length) {
          loadMoreProducts()
        }
      },
      { threshold: 0.1 }
    )

    const currentLoadingRef = loadingRef.current
    if (currentLoadingRef) {
      observer.observe(currentLoadingRef)
    }

    return () => {
      if (currentLoadingRef) {
        observer.unobserve(currentLoadingRef)
      }
    }
  }, [loadingRef, visibleProducts.length, products.length, loadMoreProducts])

  // Handle initial load of visible products
  useEffect(() => {
    if (products.length > 0 && visibleProducts.length === 0) {
      loadMoreProducts()
    }
  }, [products, loadMoreProducts, visibleProducts.length])

  useEffect(() => {
    if (!isLoading) {
      const loadDuration = performance.now() - loadStartTime.current
      trackEvent('grid_render_complete', { 
        duration: loadDuration,
        productCount: products.length 
      })
    }
  }, [isLoading, products.length, trackEvent])

  if (error) {
    return (
      <div 
        role="alert" 
        className="text-center p-6 rounded-lg border border-red-200 dark:border-red-800 bg-red-50 dark:bg-red-900/10"
      >
        <div className="mb-4">
          <svg className="w-12 h-12 text-red-500 mx-auto mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h2 className="text-lg font-semibold text-red-700 dark:text-red-400">
            Unable to Load Products
          </h2>
        </div>
        <p className="text-red-600 dark:text-red-400 mb-4">
          {error.message}
        </p>
        {error.canRetry ? (
          <button
            onClick={() => {
              trackEvent('products_retry_load')
              refetch()
            }}
            className="px-4 py-2 bg-red-100 dark:bg-red-900/30 text-red-700 dark:text-red-400 rounded-lg hover:bg-red-200 dark:hover:bg-red-900/50 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Try Again
          </button>
        ) : (
          <div className="text-sm text-red-600/80 dark:text-red-400/80">
            Please refresh the page or try again later
          </div>
        )}
        <div className="mt-6 text-sm text-gray-600 dark:text-gray-400">
          In the meantime, you can:
          <ul className="mt-2 space-y-1">
            <li>• Check out our featured products</li>
            <li>• Browse our categories</li>
            <li>• View your cart or wishlist</li>
          </ul>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div 
        className={`
          ${getProductGridClasses(viewMode)}
          transition-opacity duration-300 ease-in-out
          opacity-100
        `} 
        role="status" 
        aria-busy="true"
        aria-live="polite"
        aria-atomic="true"
        aria-relevant="additions text"
        data-testid="product-grid-loading"
      >
        {[...Array(6)].map((_, i) => (
          <ProductSkeleton 
            key={i} 
            viewMode={viewMode}
            delay={i * 150} // Stagger the animations
          />
        ))}
        <div className="sr-only">Loading products...</div>
      </div>
    )
  }

  if (!products?.length) {
    return (
      <div role="status" className="text-center p-4">
        <p>No products found.</p>
      </div>
    )
  }

  return (
    <div 
      className={getProductGridClasses(viewMode)}
      role="grid"
      aria-label="Products grid"
      aria-rowcount={Math.ceil(products.length / 3)}
      aria-colcount="3"
      data-testid="product-grid-loaded"
      onKeyDown={(e) => {
        const cards = e.currentTarget.querySelectorAll('[role="gridcell"]')
        const currentIndex = Array.from(cards).findIndex(card => card.contains(document.activeElement))
        
        switch(e.key) {
          case 'ArrowRight':
            if (currentIndex < cards.length - 1) {
              cards[currentIndex + 1].querySelector('button').focus()
            }
            break
          case 'ArrowLeft':
            if (currentIndex > 0) {
              cards[currentIndex - 1].querySelector('button').focus()
            }
            break
          case 'ArrowUp':
            if (currentIndex >= 3) {
              cards[currentIndex - 3]?.querySelector('button').focus()
            }
            break
          case 'ArrowDown':
            if (currentIndex + 3 < cards.length) {
              cards[currentIndex + 3]?.querySelector('button').focus()
            }
            break
        }
      }}
    >
      {visibleProducts.map(product => (
        <ProductCard key={product.id} product={product} />
      ))}
      {visibleProducts.length < products.length && (
        <div ref={loadingRef} className="col-span-full flex justify-center p-4">
          <ProductSkeleton />
        </div>
      )}
    </div>
  )
}

ProductGrid.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      inStock: PropTypes.bool.isRequired,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ).isRequired
}

export default ProductGrid
