import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { THEME } from '../../constants/designSystem';

export const AdminNavItem = ({ to, icon: Icon, children }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => `
        flex items-center px-4 py-3 sm:py-2 text-base sm:text-sm rounded-md transition-colors duration-200
        ${isActive 
          ? `${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY}`
          : `${THEME.COLORS.TEXT.DEFAULT} hover:bg-gray-100 dark:hover:bg-gray-800`
        }
      `}
    >
      {({isActive}) => (
        <>
          {Icon && <Icon className={`w-5 h-5 mr-3 ${isActive ? 'text-current' : THEME.COLORS.TEXT.MUTED}`} />}
          {children}
        </>
      )}
    </NavLink>
  );
};

AdminNavItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  children: PropTypes.node.isRequired
};