import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Instagram, Facebook, Youtube } from 'lucide-react';
import { THEME, ANIMATION } from '../../constants/designSystem';

// Reuse the SocialLink component logic from Header
const SocialLink = ({ href, icon: Icon, label }) => {
  if (Icon) {
    return (
      <a
        href={href}
        className={`${THEME.COLORS.TEXT.MUTED} hover:text-white transition-all ${ANIMATION.DURATION.FAST}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="sr-only">{label}</span>
        <Icon size={18} />
      </a>
    );
  }
  
  return (
    <a
      href={href}
      className={`${THEME.COLORS.TEXT.MUTED} hover:text-white transition-all ${ANIMATION.DURATION.FAST} text-xs font-light`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </a>
  );
};

SocialLink.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
  label: PropTypes.string.isRequired,
};

const socialLinks = [
  { href: 'https://facebook.com/JeanMartynMusicOfficial/', icon: Facebook, label: 'Facebook' },
  { href: 'https://instagram.com/jean.martyn/', icon: Instagram, label: 'Instagram' },
  { href: 'https://www.youtube.com/@jeanmartyn', icon: Youtube, label: 'Youtube' },
  { href: 'https://www.tiktok.com/@jean_martyn_music', label: 'TikTok' }
];

function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className={`${THEME.COLORS.BG.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT}`}>
      <div className="mx-auto max-w-7xl px-4 py-3 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-3 sm:space-y-0">
          {/* Copyright notice */}
          <p className={`${THEME.COLORS.TEXT.MUTED} text-xs font-light`}>
            © {currentYear} Jean Martyn. All rights reserved.
          </p>
          
          {/* Navigation links */}
          <div className="flex space-x-4 text-xs">
            <Link to="/contact" className={`${THEME.COLORS.TEXT.MUTED} hover:text-white transition-all ${ANIMATION.DURATION.FAST} font-light`}>
              Contact
            </Link>
            <Link to="/privacy" className={`${THEME.COLORS.TEXT.MUTED} hover:text-white transition-all ${ANIMATION.DURATION.FAST} font-light`}>
              Privacy
            </Link>
            <Link to="/terms" className={`${THEME.COLORS.TEXT.MUTED} hover:text-white transition-all ${ANIMATION.DURATION.FAST} font-light`}>
              Terms
            </Link>
          </div>

          {/* Social links */}
          <div className="flex space-x-3">
            {socialLinks.map((link) => (
              <SocialLink key={link.label} {...link} />
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
