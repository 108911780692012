import BasePage from '../components/layout/BasePage'

function AwardsPage() {
  return (
    <BasePage title="Awards">
      <div className="prose max-w-none">
        <p>Awards and recognition content will go here.</p>
      </div>
    </BasePage>
  )
}

export default AwardsPage