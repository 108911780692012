import { StorageManager } from './StorageManager';

// Priority levels for storage
const PRIORITY = {
  CRITICAL: 'critical',
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low'
};

// Event priority levels
const EVENT_PRIORITY = {
  CRITICAL: 'critical',   // Never removed (errors, purchases)
  HIGH: 'high',          // Remove last (user actions)
  MEDIUM: 'medium',      // Remove after low (page views)
  LOW: 'low'            // Remove first (hover events)
};

class AnalyticsService {
  constructor() {
    this.events = [];
    this.metrics = new Map();
    this.errors = [];
    this.maxStoredEvents = 1000;
    this.storage = new StorageManager('analytics-data');
    this.initialized = false;
    this.startTime = Date.now();
    this.lastInteraction = Date.now();
  }

  init() {
    if (this.initialized) return;

    // Track memory usage
    if (performance.memory) {
      setInterval(() => {
        this.trackMetric('memory_usage', performance.memory.usedJSHeapSize / 1048576);
      }, 10000);
    }

    // Track user interactions
    document.addEventListener('click', () => {
      const now = Date.now();
      const timeSinceLastInteraction = now - this.lastInteraction;
      this.trackMetric('interaction_time', timeSinceLastInteraction);
      this.lastInteraction = now;
    }, true);

    // Track page load time
    window.addEventListener('load', () => {
      const loadTime = Date.now() - this.startTime;
      this.trackMetric('page_load', loadTime);
    });
    
    // Load persisted data
    try {
      const data = this.storage.load();
      if (data) {
        this.events = data.events || [];
        this.errors = data.errors || [];
        this.metrics = new Map(data.metrics || []);
        
        // Ensure all events have priorities
        this.events = this.events.map(event => ({
          ...event,
          priority: event.priority || EVENT_PRIORITY.MEDIUM
        }));
      }
    } catch (error) {
      console.error('Failed to load analytics data:', error);
    }

    // Setup performance monitoring
    if ('PerformanceObserver' in window) {
      const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
          this.trackMetric(entry.name, entry.duration);
        });
      });

      observer.observe({ entryTypes: ['measure', 'resource'] });
    }

    this.initialized = true;
  }

  trackEvent(category, action, label = null, value = null, priority = EVENT_PRIORITY.MEDIUM) {
    const event = {
      category,
      action,
      label,
      value,
      priority,
      timestamp: Date.now()
    };

    this.events.push(event);
    
    // Group events by priority
    const eventsByPriority = {
      [EVENT_PRIORITY.LOW]: [],
      [EVENT_PRIORITY.MEDIUM]: [],
      [EVENT_PRIORITY.HIGH]: [],
      [EVENT_PRIORITY.CRITICAL]: []
    };

    this.events.forEach(e => {
      const p = e.priority || EVENT_PRIORITY.MEDIUM;
      eventsByPriority[p].push(e);
    });

    // If over limit, remove oldest non-critical events first
    if (this.events.length > this.maxStoredEvents) {
      const totalToRemove = this.events.length - this.maxStoredEvents;
      let removed = 0;

      // Remove events in priority order until we've removed enough
      for (const priority of [EVENT_PRIORITY.LOW, EVENT_PRIORITY.MEDIUM, EVENT_PRIORITY.HIGH]) {
        const events = eventsByPriority[priority];
        if (removed < totalToRemove && events.length > 0) {
          // Sort by timestamp and remove oldest
          events.sort((a, b) => a.timestamp - b.timestamp);
          const toRemove = Math.min(totalToRemove - removed, events.length);
          events.splice(0, toRemove);
          removed += toRemove;
        }
      }

      // Reconstruct events array preserving critical events
      this.events = [
        ...eventsByPriority[EVENT_PRIORITY.CRITICAL],
        ...eventsByPriority[EVENT_PRIORITY.HIGH],
        ...eventsByPriority[EVENT_PRIORITY.MEDIUM],
        ...eventsByPriority[EVENT_PRIORITY.LOW]
      ];
    }

    this.persistData();
  }

  trackMetric(name, value) {
    const metrics = this.metrics.get(name) || [];
    metrics.push({ value, timestamp: Date.now() });
    
    // Keep only last 100 measurements per metric
    if (metrics.length > 100) {
      metrics.splice(0, metrics.length - 100);
    }
    
    this.metrics.set(name, metrics);
    this.persistData();
  }

  logError(error, context = {}) {
    const errorLog = {
      message: error.message,
      stack: error.stack,
      context,
      timestamp: Date.now(),
      url: window.location.href,
      userAgent: navigator.userAgent
    };

    this.errors.push(errorLog);
    
    // Keep only last 100 errors
    if (this.errors.length > 100) {
      this.errors.splice(0, this.errors.length - 100);
    }

    this.persistData();
  }

  getErrorRate(timeWindow = 3600000) {
    const now = Date.now();
    const recentErrors = this.errors.filter(e => now - e.timestamp < timeWindow);
    return (recentErrors.length / (timeWindow / 1000)) * 3600; // Errors per hour
  }

  getMetricAverage(name, timeWindow = 3600000) { // Default 1 hour window
    const metrics = this.metrics.get(name) || [];
    const now = Date.now();
    const relevantMetrics = metrics.filter(m => now - m.timestamp < timeWindow);
    
    if (relevantMetrics.length === 0) return null;
    
    const sum = relevantMetrics.reduce((acc, m) => acc + m.value, 0);
    return sum / relevantMetrics.length;
  }

  async persistData() {
    try {
      const data = {
        events: this.events,
        errors: this.errors,
        metrics: Array.from(this.metrics.entries())
      };
      await this.storage.save(data, PRIORITY.HIGH); // Analytics data is high priority
    } catch (error) {
      console.error('Failed to persist analytics data:', error);
    }
  }
}

export const analytics = new AnalyticsService();
export { PRIORITY };
