import { TYPOGRAPHY, THEME } from '../../constants/designSystem';
import { QuickAccessGrid } from "../../components/admin/dashboard/QuickAccessGrid";
import { StatusOverview } from "../../components/admin/dashboard/StatusOverview";
import { ActivityLog } from "../../components/admin/dashboard/ActivityLog";

const DashboardPage = () => {
  return (
    <div className="space-y-6 sm:space-y-8">
      <div>
        <h2 className={`${TYPOGRAPHY.HEADING.H2} ${THEME.COLORS.TEXT.DEFAULT} mb-4`}>Dashboard</h2>
        <p className={THEME.COLORS.TEXT.MUTED}>
          Welcome to the admin dashboard. Here you can manage your website content and monitor performance.
        </p>
      </div>

      <section>
        <h3 className={`${TYPOGRAPHY.HEADING.H3} ${THEME.COLORS.TEXT.DEFAULT} mb-4`}>Quick Access</h3>
        <QuickAccessGrid />
      </section>

      <section>
        <h3 className={`${TYPOGRAPHY.HEADING.H3} ${THEME.COLORS.TEXT.DEFAULT} mb-4`}>Status Overview</h3>
        <StatusOverview />
      </section>

      <section>
        <ActivityLog />
      </section>
    </div>
  );
};

export default DashboardPage;
