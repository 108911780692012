import BasePage from '../components/layout/BasePage'
import TourList from '../components/tour/TourList'

function TourPage() {
  return (
    <BasePage title="Tour Dates">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <TourList />
      </div>
    </BasePage>
  )
}

export default TourPage
