import { supabase } from './SupabaseService';
import { analytics } from './AnalyticsService';

class AdminGalleryService {
  constructor() {
    this.tableName = 'gallery_images';
  }

  async getAllImages() {
    try {
      const { data, error } = await supabase
        .from(this.tableName)
        .select('id, alt_text, caption, display_order, updated_at, image_path')
        .order('display_order', { ascending: true });

      if (error) throw error;
      return data;
    } catch (error) {
      analytics.logError(error, { component: 'AdminGalleryService', method: 'getAllImages' });
      throw error;
    }
  }

  async uploadImage(file, metadata) {
    try {
      // Generate a unique filename with timestamp
      const timestamp = Date.now();
      const fileExt = file.name.split('.').pop();
      const fileName = `${timestamp}-${Math.random().toString(36).substring(2)}.${fileExt}`;
      
      // Upload file to Supabase Storage
      const { error: uploadError } = await supabase.storage
        .from('gallery-images')
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) throw uploadError;

      // Create database record
      const { data: dbData, error: dbError } = await supabase
        .from(this.tableName)
        .insert([{
          alt_text: metadata.altText,
          caption: metadata.caption,
          display_order: metadata.displayOrder,
          image_path: fileName
        }])
        .select('id, alt_text, caption, display_order, updated_at, image_path')
        .single();

      if (dbError) {
        // If database insert fails, clean up the uploaded file
        await supabase.storage
          .from('gallery-images')
          .remove([fileName]);
        throw dbError;
      }

      return dbData;
    } catch (error) {
      analytics.logError(error, { component: 'AdminGalleryService', method: 'uploadImage' });
      throw error;
    }
  }

  async updateImage(id, updates) {
    try {
      const { data, error } = await supabase
        .from(this.tableName)
        .update(updates)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      analytics.logError(error, { component: 'AdminGalleryService', method: 'updateImage' });
      throw error;
    }
  }

  async deleteImage(id) {
    try {
      // Delete the database record
      const { error: dbError } = await supabase
        .from(this.tableName)
        .delete()
        .eq('id', id);

      if (dbError) throw dbError;
    } catch (error) {
      analytics.logError(error, { component: 'AdminGalleryService', method: 'deleteImage' });
      throw error;
    }
  }

  async updateOrder(imageIds) {
    try {
      const updates = imageIds.map((id, index) => ({
        id,
        display_order: index
      }));

      const { error } = await supabase
        .from(this.tableName)
        .upsert(updates);

      if (error) throw error;
    } catch (error) {
      analytics.logError(error, { component: 'AdminGalleryService', method: 'updateOrder' });
      throw error;
    }
  }
}

export const adminGalleryService = new AdminGalleryService();
