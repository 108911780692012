import { useProducts } from '../../hooks/useProducts'
import Button from '../ui/Button'
import ProductSearch from './ProductSearch'
import ProductFilters from './ProductFilters'

function ProductGridControls() {
  const { viewMode, setViewMode, sortConfig, setSortConfig } = useProducts()

  const handleSortChange = (e) => {
    const [field, direction] = e.target.value.split('-')
    setSortConfig({ field, direction })
  }

  return (
    <div className="space-y-4 mb-6">
      <ProductSearch />
      
      <div className="flex flex-wrap items-center justify-between gap-4">
        <ProductFilters />
        
        <div className="flex items-center gap-4">
          <select
            value={`${sortConfig.field}-${sortConfig.direction}`}
            onChange={handleSortChange}
            className="rounded-lg border border-gray-300 dark:border-gray-700 px-3 py-2"
          >
            <option value="name-asc">Name (A-Z)</option>
            <option value="name-desc">Name (Z-A)</option>
            <option value="price-asc">Price (Low-High)</option>
            <option value="price-desc">Price (High-Low)</option>
          </select>

          <div className="flex gap-2" role="toolbar" aria-label="Product view options">
            <Button
              variant={viewMode === 'grid' ? 'primary' : 'outline'}
              size="sm"
              onClick={() => setViewMode('grid')}
              aria-pressed={viewMode === 'grid'}
              aria-label="Grid view"
            >
              Grid
            </Button>
            <Button
              variant={viewMode === 'list' ? 'primary' : 'outline'}
              size="sm"
              onClick={() => setViewMode('list')}
              aria-pressed={viewMode === 'list'}
              aria-label="List view"
            >
              List
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductGridControls
