import { Component } from 'react';
import PropTypes from 'prop-types';
import { THEME, TYPOGRAPHY } from '../constants/designSystem';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={`${THEME.COLORS.BG.DEFAULT} p-6 rounded-lg shadow-lg`}>
          <h2 className={TYPOGRAPHY.HEADING.H2}>Something went wrong</h2>
          <p className="mt-2 text-red-600">
            {this.state.error?.message || 'An unexpected error occurred'}
          </p>
          <button
            onClick={() => window.location.reload()}
            className={`mt-4 px-4 py-2 rounded-lg ${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY}`}
          >
            Reload Page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
