import { useForm } from "react-hook-form"
import { sendContactEmail } from "../../lib/email"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { SPACING, TYPOGRAPHY, THEME } from '../../constants/designSystem'
import { cn } from '../../lib/utils'
import { contactFormSchema } from "../../lib/validations/contact"
import FormMessage from "../ui/FormMessage"

const ContactForm = () => {
  const [submitStatus, setSubmitStatus] = useState({
    message: '',
    type: 'success'
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: zodResolver(contactFormSchema),
    mode: "onBlur"
  })

  const onSubmit = async (data) => {
    try {
      setSubmitStatus({ message: '', type: null })
      
      const result = await sendContactEmail(data)
      
      if (result.success) {
        setSubmitStatus({
          message: 'Thank you for your message. We will get back to you soon!',
          type: 'success'
        })
        reset()
      } else {
        throw new Error('Failed to send message')
      }
    } catch (error) {
      // Use custom error message if available, otherwise fallback to generic message
      const errorMessage = error.userMessage || 'Sorry, there was a problem sending your message. Please try again.'
      
      setSubmitStatus({
        message: errorMessage,
        type: 'error'
      })
      
      // Log form submission error for debugging (sensitive data already removed by this point)
      console.error('Form submission error:', {
        errorType: error.name,
        timestamp: new Date().toISOString(),
        formId: 'contact-form'
      })
    }
  }

  const inputClassName = cn(
    "w-full p-2 border rounded-md",
    `${THEME.COLORS.BORDER.DEFAULT} ${THEME.COLORS.BG.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT}`,
    "focus:outline-none focus:ring-2",
    `${THEME.COLORS.HOVER.BG.DEFAULT}`,
    TYPOGRAPHY.BODY.DEFAULT,
    "transition-colors duration-200"
  )

  const labelClassName = cn(
    "block mb-2",
    TYPOGRAPHY.BODY.DEFAULT,
    THEME.COLORS.TEXT.DEFAULT
  )

  const errorClassName = cn(
    `${THEME.COLORS.TEXT.MUTED} ${TYPOGRAPHY.BODY.SMALL}`,
    TYPOGRAPHY.BODY.SMALL
  )

  return (
    <form 
      onSubmit={handleSubmit(onSubmit)} 
      className={`${SPACING.DEFAULT} max-w-2xl mx-auto`}
      aria-label="Contact form"
      noValidate
    >
      <FormMessage type={submitStatus.type} message={submitStatus.message} />
      <div className="space-y-4">
        <div>
          <label htmlFor="name" className={labelClassName}>Name</label>
          <input
            type="text"
            id="name"
            className={cn(
              inputClassName,
              errors.name && "border-red-500"
            )}
            {...register("name")}
            aria-invalid={errors.name ? "true" : "false"}
            aria-describedby={errors.name ? "name-error" : undefined}
          />
          {errors.name && (
            <p className={errorClassName} role="alert" id="name-error">{errors.name.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="email" className={labelClassName}>Email</label>
          <input
            type="email"
            id="email"
            className={cn(
              inputClassName,
              errors.email && "border-red-500"
            )}
            {...register("email")}
            aria-invalid={errors.email ? "true" : "false"}
            aria-describedby={errors.email ? "email-error" : undefined}
          />
          {errors.email && (
            <p className={errorClassName} role="alert" id="email-error">{errors.email.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="subject" className={labelClassName}>Subject</label>
          <input
            type="text"
            id="subject"
            className={cn(
              inputClassName,
              errors.subject && "border-red-500"
            )}
            {...register("subject")}
            aria-invalid={errors.subject ? "true" : "false"}
            aria-describedby={errors.subject ? "subject-error" : undefined}
          />
          {errors.subject && (
            <p className={errorClassName} role="alert" id="subject-error">{errors.subject.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="message" className={labelClassName}>Message</label>
          <textarea
            id="message"
            rows="5"
            className={cn(
              inputClassName,
              errors.message && "border-red-500"
            )}
            {...register("message")}
            aria-invalid={errors.message ? "true" : "false"}
            aria-describedby={errors.message ? "message-error" : undefined}
          />
          {errors.message && (
            <p className={errorClassName} role="alert" id="message-error">{errors.message.message}</p>
          )}
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={cn(
            "w-full md:w-auto px-6 py-2 bg-gray-900 dark:bg-gray-800 text-white rounded-md",
            "hover:bg-gray-800 dark:hover:bg-gray-700 transition-colors",
            "disabled:opacity-50 disabled:cursor-not-allowed",
            TYPOGRAPHY.BODY.DEFAULT
          )}
        >
          <span className="flex items-center justify-center" aria-hidden={isSubmitting}>
            {isSubmitting ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Sending...
              </>
            ) : (
              "Send Message"
            )}
          </span>
        </button>
      </div>
    </form>
  )
}

export default ContactForm
