import PropTypes from 'prop-types'
import { THEME } from '../../constants/designSystem'
import { getShippingOptions, calculateDeliveryDate, formatDeliveryDate } from '../../utils/ShippingUtils'
import { formatPrice } from '../../utils/ProductUtils'

function ShippingOptions({ countryCode, selectedOption, onSelect }) {
  const options = getShippingOptions(countryCode)
  
  return (
    <div className="space-y-4">
      <h3 className="font-semibold text-lg mb-2">Shipping Method</h3>
      <div className="space-y-2">
        {options.map(option => {
          const { minDate, maxDate } = calculateDeliveryDate(option)
          const deliveryRange = option.estimatedDays.min === option.estimatedDays.max
            ? formatDeliveryDate(minDate)
            : `${formatDeliveryDate(minDate)} - ${formatDeliveryDate(maxDate)}`

          return (
            <label
              key={option.id}
              className={`
                block p-4 rounded-lg border cursor-pointer
                min-h-[4.5rem]
                touch-manipulation
                ${selectedOption?.id === option.id 
                  ? 'border-primary-500 bg-primary-50 dark:bg-primary-900/10'
                  : 'border-gray-200 dark:border-gray-700'}
                hover:border-primary-500 transition-colors
              `}
            >
              <div className="flex items-center gap-4">
                <input
                  type="radio"
                  name="shipping"
                  value={option.id}
                  checked={selectedOption?.id === option.id}
                  onChange={() => onSelect(option)}
                  className="text-primary-600"
                />
                <div className="flex-grow">
                  <div className="flex justify-between items-start">
                    <div>
                      <span className="font-medium">{option.name}</span>
                      <p className={`text-sm ${THEME.COLORS.TEXT.MUTED}`}>
                        {option.description}
                      </p>
                    </div>
                    <span className="font-semibold">
                      {formatPrice(option.price)}
                    </span>
                  </div>
                  <p className={`text-sm ${THEME.COLORS.TEXT.MUTED} mt-1`}>
                    Estimated delivery: {deliveryRange}
                  </p>
                </div>
              </div>
            </label>
          )
        })}
      </div>
    </div>
  )
}

ShippingOptions.propTypes = {
  countryCode: PropTypes.string.isRequired,
  selectedOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    estimatedDays: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired
    }).isRequired,
    description: PropTypes.string.isRequired
  }),
  onSelect: PropTypes.func.isRequired
}

export default ShippingOptions
