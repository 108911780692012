// src/components/maintenance/PreviewAccess.jsx
import { useState } from 'react';
import { THEME } from '../../constants/designSystem';

export const PreviewAccess = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (code === import.meta.env.VITE_PREVIEW_CODE) {
      localStorage.setItem('preview_access', 'true');
      window.location.reload();
    } else {
      setError('Invalid preview code');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-8">
      <input 
        type="password"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder="Enter preview code"
        className={`px-4 py-2 rounded border ${THEME.COLORS.BG.DEFAULT} ${THEME.COLORS.TEXT.DEFAULT}`}
      />
      {error && <div className="text-red-500 mt-2">{error}</div>}
    </form>
  );
};