// src/components/home/GridLayout.jsx
import PropTypes from 'prop-types';
import { CELL_TYPES, CELL_SPANS, CELL_ASPECT_RATIOS, defaultGridItems } from './gridConstants';
import { GRID, THEME } from '../../constants/designSystem';
import GridItem from './GridItem';

const GridCell = ({ item }) => (
  <div
    key={item.id}
    className={`item relative ${CELL_SPANS[item.type]}`}
  >
    <div 
      className="relative w-full h-0 overflow-hidden"
      style={{ paddingBottom: CELL_ASPECT_RATIOS[item.type] }}
    >
      <GridItem {...item} />
    </div>
  </div>
);

const GridLayout = ({ items = defaultGridItems }) => {
  return (
    <div 
      id="grid" 
      className={`w-full ${THEME.COLORS.BG.SECONDARY}`}
      role="region" 
      aria-label="Content Grid"
    >
      <div className={`
        grid ${GRID.COLS.DEFAULT} ${GRID.COLS.MD} ${GRID.COLS.LG} ${GRID.GAPS}
      `}>
        {items.map((item) => (
          <GridCell key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

GridLayout.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(CELL_TYPES))
  }))
};

GridCell.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(CELL_TYPES)).isRequired
  }).isRequired
};

export default GridLayout;