import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { THEME } from '../../../../constants/designSystem';

const AdminTourDateForm = ({ tour, onSubmit, onCancel }) => {
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    date: '',
    time: '',
    venue: '',
    address: '',
    city: '',
    postCode: '',
    country: '',
    status: 'draft',
    eventType: 'ticketed',
    bookingLink: '',
    priceNotes: '',
    venueNotes: '',
    additionalInfo: ''
  });

  useEffect(() => {
    if (tour) {
      setFormData(tour);
    }
  }, [tour]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await onSubmit(formData);
    } catch (err) {
      setError(err.message || 'Failed to save tour date. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Date and Time */}
        <div>
          <label className="block text-sm font-medium mb-1">Date</label>
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Time</label>
          <input
            type="time"
            name="time"
            value={formData.time}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        {/* Venue Details */}
        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Venue</label>
          <input
            type="text"
            name="venue"
            value={formData.venue}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Address</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">City</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Post Code</label>
          <input
            type="text"
            name="postCode"
            value={formData.postCode}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Country</label>
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        {/* Event Details */}
        <div>
          <label className="block text-sm font-medium mb-1">Status</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          >
            <option value="draft">Draft</option>
            <option value="published">Published</option>
            <option value="archived">Archived</option>
            <option value="pending">Pending</option>
            <option value="upcoming">Upcoming</option>
            <option value="sold_out">Sold Out</option>
            <option value="cancelled">Cancelled</option>
            <option value="postponed">Postponed</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Event Type</label>
          <select
            name="eventType"
            value={formData.eventType}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          >
            <option value="ticketed">Ticketed</option>
            <option value="door_sales">Door Sales</option>
            <option value="mixed">Mixed</option>
            <option value="free">Free</option>
            <option value="private">Private</option>
          </select>
        </div>

        {/* Additional Details */}
        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Booking Link</label>
          <input
            type="url"
            name="bookingLink"
            value={formData.bookingLink}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Price Notes</label>
          <textarea
            name="priceNotes"
            value={formData.priceNotes}
            onChange={handleChange}
            rows="2"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Venue Notes</label>
          <textarea
            name="venueNotes"
            value={formData.venueNotes}
            onChange={handleChange}
            rows="2"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="md:col-span-2">
          <label className="block text-sm font-medium mb-1">Additional Info</label>
          <textarea
            name="additionalInfo"
            value={formData.additionalInfo}
            onChange={handleChange}
            rows="3"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
      </div>

      {error && (
        <div className="p-4 mb-4 text-red-700 bg-red-100 dark:bg-red-900 dark:text-red-200 rounded-md">
          <p className="font-medium">Error Saving Tour Date</p>
          <p className="text-sm mt-1">{error}</p>
        </div>
      )}
      <div className="flex justify-end gap-4">
        <button
          type="button"
          onClick={onCancel}
          className={`${THEME.COLORS.BG.SECONDARY} ${THEME.COLORS.TEXT.ON_SECONDARY} px-4 py-2 rounded-md`}
        >
          Cancel
        </button>
        <button
          type="submit"
          className={`${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY} px-4 py-2 rounded-md`}
        >
          {tour ? 'Update Tour Date' : 'Create Tour Date'}
        </button>
      </div>
    </form>
  );
};

AdminTourDateForm.propTypes = {
  tour: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.string.isRequired,
    time: PropTypes.string,
    venue: PropTypes.string.isRequired,
    address: PropTypes.string,
    city: PropTypes.string.isRequired,
    postCode: PropTypes.string,
    country: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    eventType: PropTypes.string.isRequired,
    bookingLink: PropTypes.string,
    priceNotes: PropTypes.string,
    venueNotes: PropTypes.string,
    additionalInfo: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default AdminTourDateForm;
