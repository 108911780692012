import { useState } from 'react';
import PropTypes from 'prop-types';
import { THEME } from '../../constants/designSystem';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

const ImageUploadForm = ({ onUpload, onCancel }) => {
  const [file, setFile] = useState(null);
  const [metadata, setMetadata] = useState({
    altText: '',
    caption: ''
  });
  const [preview, setPreview] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        alert('File is too large. Maximum size is 5MB.');
        e.target.value = ''; // Reset the input
        return;
      }
      
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => setPreview(reader.result);
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) return;

    setIsUploading(true);
    setError(null);
    try {
      await onUpload(file, metadata);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        <div className="border-2 border-dashed rounded-lg p-4 text-center">
          {preview ? (
            <div className="relative">
              <img
                src={preview}
                alt="Upload preview"
                className="max-h-64 mx-auto rounded"
              />
              <button
                type="button"
                onClick={() => {
                  setFile(null);
                  setPreview('');
                }}
                className="absolute top-2 right-2 p-1 rounded-full bg-red-600 text-white"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          ) : (
            <div>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="hidden"
                id="image-upload"
              />
              <label
                htmlFor="image-upload"
                className="cursor-pointer inline-flex flex-col items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <span className="mt-2 text-sm text-gray-600">Click to upload an image</span>
              </label>
            </div>
          )}
        </div>

        <div>
          <label htmlFor="altText" className="block text-sm font-medium mb-1">
            Alt Text
          </label>
          <input
            type="text"
            id="altText"
            value={metadata.altText}
            onChange={(e) => setMetadata(prev => ({ ...prev, altText: e.target.value }))}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
            required
          />
        </div>

        <div>
          <label htmlFor="caption" className="block text-sm font-medium mb-1">
            Caption
          </label>
          <textarea
            id="caption"
            value={metadata.caption}
            onChange={(e) => setMetadata(prev => ({ ...prev, caption: e.target.value }))}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500"
            rows={3}
          />
        </div>
      </div>

      {error && (
        <div className="p-3 rounded bg-red-100 border border-red-400 text-red-700">
          {error}
        </div>
      )}
      <div className="flex justify-end gap-3">
        <button
          type="button"
          onClick={onCancel}
          className={`px-4 py-2 rounded-lg ${THEME.COLORS.BG.MUTED} ${THEME.COLORS.TEXT.DEFAULT}`}
          disabled={isUploading}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={!file || isUploading}
          className={`px-4 py-2 rounded-lg ${THEME.COLORS.BG.PRIMARY} ${THEME.COLORS.TEXT.ON_PRIMARY}
            ${(!file || isUploading) ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isUploading ? 'Uploading...' : 'Upload Image'}
        </button>
      </div>
    </form>
  );
};

ImageUploadForm.propTypes = {
  onUpload: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ImageUploadForm;
