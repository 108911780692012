import PropTypes from 'prop-types';
import { THEME } from '../../constants/designSystem';

export const AdminContainer = ({ children }) => {
  return (
    <div className={`
      min-h-screen pt-16 
      ${THEME.COLORS.BG.MUTED}
    `}>
      <div className="max-w-7xl mx-auto px-3 sm:px-4 md:px-6 lg:px-8 py-4 sm:py-6">
        <div className={`
          ${THEME.COLORS.BG.DEFAULT} 
          shadow-sm rounded-lg p-4 sm:p-6
        `}>
          {children}
        </div>
      </div>
    </div>
  );
};

AdminContainer.propTypes = {
  children: PropTypes.node.isRequired
};
