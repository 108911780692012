import { Alert, AlertTitle, AlertDescription } from '../../components/ui/alert';
import { HardHat } from 'lucide-react';

const UnderConstructionBanner = () => {
  return (
    <Alert 
      variant="warning" 
      className="rounded-none border-x-0 border-t-0"
    >
      <HardHat className="h-4 w-4" />
      <AlertTitle className="text-yellow-800 dark:text-yellow-200 items-center">
        Website Under Construction
      </AlertTitle>
      <AlertDescription className="text-yellow-700 dark:text-yellow-300 items-center">
        This website is currently being developed. Some features may be incomplete or use placeholder content.
      </AlertDescription>
    </Alert>
  );
};

export default UnderConstructionBanner;