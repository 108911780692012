import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { THEME } from '../../constants/designSystem'
import { formatPrice } from '../../utils/ProductUtils'
import { formatOrderDate } from '../../utils/OrderUtils'

function OrderConfirmation({ 
  orderReference,
  orderDate,
  items,
  shippingAddress,
  shippingOption,
  totalAmount,
  vatAmount,
  onClose
}) {
  useEffect(() => {
    // Announce to screen readers
    const message = `Order ${orderReference} confirmed. A confirmation email has been sent.`
    const announcement = document.createElement('div')
    announcement.setAttribute('role', 'alert')
    announcement.setAttribute('aria-live', 'polite')
    announcement.className = 'sr-only'
    announcement.textContent = message
    document.body.appendChild(announcement)

    return () => announcement.remove()
  }, [orderReference])

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <div className="text-center mb-8">
        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-100 dark:bg-green-900 mb-4">
          <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h2 className="text-2xl font-bold mb-2">Order Confirmed!</h2>
        <p className={`${THEME.COLORS.TEXT.MUTED}`}>
          Order Reference: {orderReference}
        </p>
        <p className={`${THEME.COLORS.TEXT.MUTED}`}>
          {formatOrderDate(orderDate)}
        </p>
      </div>

      <div className="border-t border-b py-4 mb-4">
        <h3 className="font-semibold mb-2">Order Summary</h3>
        {items.map(item => (
          <div key={item.id} className="flex justify-between mb-2">
            <div>
              <span className="font-medium">{item.name}</span>
              <span className={`${THEME.COLORS.TEXT.MUTED} ml-2`}>x{item.quantity}</span>
            </div>
            <span>{formatPrice(item.price * item.quantity)}</span>
          </div>
        ))}
        
        <div className="border-t mt-4 pt-4">
          <div className="flex justify-between mb-2">
            <span>Subtotal</span>
            <span>{formatPrice(totalAmount - vatAmount)}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span>VAT</span>
            <span>{formatPrice(vatAmount)}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span>Shipping ({shippingOption.name})</span>
            <span>{formatPrice(shippingOption.price)}</span>
          </div>
          <div className="flex justify-between font-bold mt-2 pt-2 border-t">
            <span>Total</span>
            <span>{formatPrice(totalAmount)}</span>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="font-semibold mb-2">Shipping Address</h3>
        <div className={`${THEME.COLORS.TEXT.MUTED}`}>
          <p>{shippingAddress.name}</p>
          <p>{shippingAddress.street}</p>
          <p>{shippingAddress.city}</p>
          <p>{shippingAddress.postalCode}</p>
          <p>{shippingAddress.country}</p>
        </div>
      </div>

      <div className="text-center">
        <p className="mb-4">
          A confirmation email has been sent to your email address.
        </p>
        <button
          onClick={onClose}
          className={`
            px-6 py-2 rounded-lg
            ${THEME.COLORS.BG.PRIMARY}
            ${THEME.COLORS.TEXT.ON_PRIMARY}
            hover:opacity-90 transition-opacity
          `}
        >
          Continue Shopping
        </button>
      </div>
    </div>
  )
}

OrderConfirmation.propTypes = {
  orderReference: PropTypes.string.isRequired,
  orderDate: PropTypes.instanceOf(Date).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired
  })).isRequired,
  shippingAddress: PropTypes.shape({
    name: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired
  }).isRequired,
  shippingOption: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired
  }).isRequired,
  totalAmount: PropTypes.number.isRequired,
  vatAmount: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
}

export default OrderConfirmation
