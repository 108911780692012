// Shipping rates and estimates
export const UK_SHIPPING_OPTIONS = [
  {
    id: 'uk-standard',
    name: 'Standard Delivery',
    price: 3.99,
    estimatedDays: { min: 3, max: 5 },
    description: '3-5 working days'
  },
  {
    id: 'uk-express',
    name: 'Express Delivery',
    price: 6.99,
    estimatedDays: { min: 1, max: 2 },
    description: '1-2 working days'
  },
  {
    id: 'uk-next-day',
    name: 'Next Day Delivery',
    price: 9.99,
    estimatedDays: { min: 1, max: 1 },
    description: 'Next working day if ordered before 2pm'
  }
]

export const EU_SHIPPING_OPTIONS = [
  {
    id: 'eu-standard',
    name: 'Standard International',
    price: 9.99,
    estimatedDays: { min: 5, max: 7 },
    description: '5-7 working days'
  },
  {
    id: 'eu-express',
    name: 'Express International',
    price: 14.99,
    estimatedDays: { min: 3, max: 4 },
    description: '3-4 working days'
  }
]

export const getShippingOptions = (countryCode) => {
  return countryCode === 'GB' ? UK_SHIPPING_OPTIONS : EU_SHIPPING_OPTIONS
}

export const calculateDeliveryDate = (shippingOption) => {
  const today = new Date()
  const minDate = new Date(today)
  const maxDate = new Date(today)
  
  minDate.setDate(today.getDate() + shippingOption.estimatedDays.min)
  maxDate.setDate(today.getDate() + shippingOption.estimatedDays.max)
  
  return {
    minDate,
    maxDate
  }
}

export const formatDeliveryDate = (date) => {
  return date.toLocaleDateString('en-GB', {
    weekday: 'long',
    day: 'numeric',
    month: 'long'
  })
}
